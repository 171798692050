import { HiOutlineShoppingCart } from "react-icons/hi";
import "./CartComponentStyles.css"
import React from "react";
import PaymentDetailsComponent from "./PaymentDetailsComponent";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import { useTheme } from "../../../hooks/ThemeContext";
import CartCards from "./CartCards";
import TimeService from "../../../service/TimeService";
import { toast } from "react-toastify";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import Spinner from "../../Spinner/Spinner";


type Props = {
    cart: any[],
    setCart: React.Dispatch<React.SetStateAction<any[]>>,
    productData: any[],
    menuSettings: any[],
    openingHours: any,
    additionalCharges: any[],
    testMode?: boolean,
}


const CartComponent = (props: Props) => {

    let stripePromise;

    if (props.testMode && process.env.REACT_APP_STRIPE_TEST_KEY) {
        console.log("In Stripe Test Mode")
        stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY, {stripeAccount: "acct_1Q7OCQRvhY3LMkpN"});
    }
    else {
        if (process.env.REACT_APP_STRIPE_LIVE_KEY) {
            // console.log("In Stripe Live Mode")
            stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY, {stripeAccount: "acct_1QCQMmDRdbCLPq26"});
        }
    }


    const currencySymbol = "£";
    const [additionalCharges, setAdditionalCharges] = React.useState<any[]>([]);
    const [total, setTotal] = React.useState(0);

    const [paymentPageView, setPaymentPageView] = React.useState(false);

    const { theme } = useTheme();

    const handlePayPress = async () => {
        if (props.cart.length > 0) {

            let allowedMenus = await TimeService.getAllowedMenus(props.menuSettings);
            let updatedCart = false;

            for (let orderItem of props.cart) {
                let menuFound = false;
                for (let menuItem of orderItem.menus) {
                    if (allowedMenus.includes(menuItem)) {
                        menuFound = true;
                    }
                }
                if (menuFound == false) {
                    updatedCart = true;
                    updateQuantity(orderItem, 0);
                }

            }
            if (!updatedCart) {
                setPaymentPageView(true);
            }
            else {
                toast(`Unavailable items have been removed from your cart. Please check cart before proceeding!`, {
                    style: {
                        backgroundColor: "darkred", // Set the toast background color to green
                        color: "white",
                        minHeight: "80px",
                        borderRadius: "15px",
                    },
                });
            }
        }
    }

    React.useEffect(() => {

        if (props.productData.length > 0 && props.additionalCharges) {

            let continuingTotal = 0;
            let total = 0;
            let addonCharges: any[] = [];

            props.cart.map((item, index) => {
                let mainProduct = props.productData.filter((product) => product.productId === item.id)[0]
                let additionalProducts = props.productData.filter((product) => item.additionalOptionIds.includes(product.productId))
                let totalPrice = mainProduct.productPrice;

                for (let subproduct of additionalProducts) {
                    totalPrice += subproduct.productPrice;
                }

                continuingTotal += totalPrice*item.quantity*100

            })

            continuingTotal = continuingTotal / 100;

            addonCharges.push({name: "Subtotal", amount: continuingTotal})
            total += continuingTotal;

            props.additionalCharges.map((charge) => {
                if (charge.operation === "%") {
                    addonCharges.push({name: charge.name, amount: Math.round((continuingTotal * charge.amount/100 + Number.EPSILON) * 100) /100, description: charge.description || undefined})
                    total += Math.round((continuingTotal * charge.amount/100 + Number.EPSILON) * 100) / 100;
                }
                else if (charge.operation === "+") {
                    addonCharges.push({name: charge.name, amount: charge.amount, description: charge.description || undefined})
                    total += charge.amount;
                }
            })

            setTotal(total);
            setAdditionalCharges(addonCharges);

        }

    }, [props.cart, props.productData, props.additionalCharges])


    const updateQuantity = (existingProduct: any, newQuantity: number) => {

        let existingItem: any;

        for (let product of props.cart) {
            if (product.id === existingProduct.id && JSON.stringify(product.additionalOptionIds) === JSON.stringify(existingProduct.additionalOptionIds) && product.additionalRequests === existingProduct.additionalRequests) {
                existingItem = product;
                existingItem.quantity = newQuantity;
            }
        }

        if (existingItem) {
            if (existingItem.quantity > 0) {
                props.setCart(prevCart => prevCart.map((p) => (p.id === existingItem.id && JSON.stringify(p.additionalOptionIds) === JSON.stringify(existingItem.additionalOptionIds) && p.additionalRequests === existingItem.additionalRequests) ? existingItem : p))
            }
            else {
                props.setCart(prevCart => prevCart.filter((p) => !(p.id === existingItem.id && JSON.stringify(p.additionalOptionIds) === JSON.stringify(existingItem.additionalOptionIds) && p.additionalRequests === existingItem.additionalRequests)))
            }
        }
    }

    // return (
    //     <div className="cart-outer" style={{display: "flex", color: theme.cartTextColor, flexGrow: "1", flexShrink: "1", flexDirection: "column", padding: "20px", overflowY: "scroll"}}>
    //         <p>Online ordering will be available soon</p>
    //     </div>
    // )


    if (!paymentPageView) {
        return (
            <>
            <div className="cart-outer" style={{display: "flex", color: theme.cartTextColor, flexGrow: "1", flexShrink: "1", flexDirection: "column", padding: "20px", overflowY: "scroll"}}>
                    

                
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <span style={{display: "flex", alignItems: "center", marginRight: "10px", fontSize: "xx-large"}}><HiOutlineShoppingCart /></span>
                    <h2 style={{fontWeight: "500"}}>My Cart</h2> 
                </div>

                <div style={{display: "flex", flexDirection: "column", flexGrow: "1"}}>

                    {props.productData.length > 0 && 
                    
                    <CartCards productData={props.productData} cart={props.cart} currencySymbol={currencySymbol} updateQuantity={updateQuantity}/>                    
                    
                    }


                    {props.cart.length < 1 && 
                    
                        <p style={{marginLeft: "15px"}}>You have no items in your cart.</p>
                    }

                </div>

            </div>


                {
                    props.cart.length > 0 && 
                    <div style={{display: "flex", flexGrow: "0", minHeight: "50px", flexDirection: "column", color: theme.cartTextColor, borderTop: `1px solid ${theme.cartSummaryBorder}`, width: "100%", padding: "5%", boxSizing: "border-box", flexShrink: "0"}}> 

                        {
                            additionalCharges.map((charge, index) => {
                                return (
                                    <div key={index} style={{display: "flex", flexDirection: "row", width: "100%", marginTop: "5px"}}>
                                        <span style={{display: "flex", flexGrow: "1"}}>{charge.name} &nbsp; {charge.description && <span data-tooltip-id="my-tooltip" data-tooltip-content={charge.description}>&#9432;</span>}</span>
                                        <span>{currencySymbol + charge.amount.toFixed(2)}</span>
                                    </div>
                                )
                            })

                        }

                        <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop: "10px"}}>
                            <span style={{display: "flex", flexGrow: "1", fontWeight: "bold"}}>Total</span>
                            <span style={{fontWeight: "bold"}}>{currencySymbol + total.toFixed(2)}</span>
                        </div>

                    </div>

                }

                {props.cart.length > 0 &&
                        <div onClick={handlePayPress} style={{display: "flex", width: "100%", backgroundColor: theme.payButtonColor, color: theme.payButtonTextColor, justifyContent: "center", alignItems: "center", cursor: "pointer", height: "50px", boxSizing: "border-box"}}>
                            Pay Now
                        </div>
                }

                <Tooltip id="my-tooltip" />

            </>
        )
    }
    else {
        return (
            <>
            { stripePromise &&
            <Elements stripe={stripePromise}>
                <PaymentDetailsComponent amount={total} order={props.cart} setCart={props.setCart} updateQuantity={updateQuantity} menuSettings={props.menuSettings} openingHours={props.openingHours} onBack={()=>setPaymentPageView(false)} testMode={props.testMode || false}/>
            </Elements>
            }
            { !stripePromise &&
                <Spinner color={theme.loaderColor} />
            }
            </>
        )
    }
}

export default CartComponent;