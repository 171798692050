import React, { useState } from 'react';
import './App.css';
import "./mobile_styles/App.css"
import Topbar from './components/Topbar';
import DefaultSidebar from './components/DefaultSidebar';
import AppContext from './AppContext';
import SignIn from './components/SignIn';
import { HiOutlineShoppingCart, HiShoppingCart } from 'react-icons/hi';
import { useTheme } from './hooks/ThemeContext';

type Props = {
  children: React.ReactNode,
  sidebarPages?: any,
  showSidebar?: boolean,
  showTopbar?: boolean,
}

function RootPage(props: Props) {

  const [activeUserId, setActiveUserId] = useState("unset");
  const [sidebarOpen, setSidebarOpen] = React.useState(true);

  const [showTopbar, setShowTopbar] = React.useState(true);
  const [showDefaultSidebar, setShowDefaultSidebar] = React.useState(true);

  React.useEffect(() => {
    if (props.showSidebar) {
      setShowDefaultSidebar(props.showSidebar);
    }
    if (props.showTopbar) {
      setShowTopbar(props.showTopbar);
    }

  }, [props.showSidebar, props.showTopbar])

  const [overlappingSidebar, setOverlappingSidebar] = React.useState(false);

  const [mobileBottomBar, setMobileBottomBar] = React.useState(true);

  const [nightMode, setNightMode] = React.useState(false);

  const [showCartButton, setShowCartButton] = React.useState(false);

  const { theme } = useTheme();

  const [appContext, setAppContext] = React.useState({
    "appTheme": "dark"
  })


    return (
      <AppContext.Provider value={appContext}>
      <div className="App" style={{display: "flex", height: "80px"}}>

        {showTopbar && <Topbar 
          setActiveUserId={setActiveUserId}
          setSidebarOpen={setSidebarOpen}
          showSidebarToggle={true} 
          height={"6vh"}
          mobileBottomBar={mobileBottomBar}
          borderBottom='5px solid #0a1239'
        >

          {/* <SignIn setActiveUserId={setActiveUserId} /> */}
          
        </Topbar>}

        <div className={mobileBottomBar ? 'below-topbar horizontal-bar' : 'below-topbar vertical-bar'} style={{display: "flex", width: "100vw", flexGrow: 1, overflowY: "hidden"}}>
          
            {showDefaultSidebar && sidebarOpen && props.sidebarPages &&
            
              <DefaultSidebar 
                sidebarPages={props.sidebarPages}
                overlappingBar={overlappingSidebar} 
                showSidebar={showDefaultSidebar} 
                setActiveUserId={setActiveUserId}
                mobileBottomBar={mobileBottomBar}
                sleekBar={true}
                //icon size
              />
              
            }

            <div className='page-container' style={{backgroundColor: "white"}}>

              {props.children}

            </div>
        </div>


      </div>
      </AppContext.Provider>
    );
}

export default RootPage;
