import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ToastService {

    public static showToast = async (message: string, options: any) => {
        const TOAST_ID = "sultan-toast";

        if (!toast.isActive(TOAST_ID)) {
            // If no toast with this ID is active, create a new toast with the custom ID and options
            toast(message, { ...options, toastId: TOAST_ID });
        } else {
            // If active, update the toast with the new message and options
            toast.update(TOAST_ID, { render: message, ...options });
        }
    }
}

export default ToastService;