import React, { useState } from "react";
import CatalogueComponent from "../CatalogueComponent/CatalogueComponent";
import SlideshowComponent from "../SlideshowComponent/SlideshowComponent";
import Spinner from "../../Spinner/Spinner";
import { useTheme } from "../../../hooks/ThemeContext";
import "./MenuComponentStyles.css"


type Props = {
    addToCart: any;
    productData: any[],
    menuSettings: any[],
    containerRef?: any,
    openingHours: any,
}

const MenuComponent = (props: Props) => {

    const [menuLoading, setMenuLoading] = useState(true);
    const { theme } = useTheme();

    return(
        <div className="a" style={{width: "100%", height: "100%", padding: "2%", boxSizing: "border-box"}}>
            <div className="b" style={{display: menuLoading ? "none" : "flex", flexDirection: "column", width: "100%"}}>
                <SlideshowComponent height={"200px"} />
                
                <div style={{width: "100%"}}>
                    <CatalogueComponent containerRef={props.containerRef} productData={props.productData} menuSettings={props.menuSettings} addToCart={props.addToCart} menuLoading={menuLoading} setMenuLoading={setMenuLoading} openingHours={props.openingHours}/>
                </div>
            </div>
            <div style={{display: menuLoading ? "flex" : "none", width: "100%", height: "100%", alignItems: "center"}}>
                <Spinner color={theme.loaderColor}/>
            </div>
        </div>
    )
}

export default MenuComponent;