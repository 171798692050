import React from "react";
import { useTheme } from "../../hooks/ThemeContext";
import CartComponent from "../CartPage/CartComponent/CartComponent";

type Props = {
    cart: any[],
    setCart: React.Dispatch<React.SetStateAction<any[]>>,
    productData: any[],
    menuSettings: any[],
    openingHours: any,
    additionalCharges: any[],
    testMode?: boolean
}


const CartPage = (props: Props) => {

    const { theme } = useTheme();


    return (
        <div className="cart-page-container" style={{width: "100%", minWidth: "300px", boxSizing: "border-box", backgroundColor: theme.cartBackgroundColor, overflowY: "scroll", userSelect: "none"}}>
            <CartComponent cart={props.cart} setCart={props.setCart} productData={props.productData} menuSettings={props.menuSettings} openingHours={props.openingHours} additionalCharges={props.additionalCharges} testMode={props.testMode || false}/>
        </div>
    )

}

export default CartPage;