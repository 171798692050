import React from "react";


const Logo = () => {

    return (
        <>
            {/* <span className="app-primary-color" style={{fontSize: "x-large", fontWeight: "1000", color: "black"}}>DinePlatform</span> */}
            {/* <div style={{display: "flex", height: "100%", color: "#F8B807", textAlign: "center", alignItems: "center", justifyContent: "center", fontSize: "25px"}}><span>SULTANS</span></div> */}
            <img src="https://i.ibb.co/SQCRnn0/transparent-logo.png" height="80%" />
            {/* <span style={{color: "white"}}>.IO</span> */}
        </>
    )
}

export default Logo;