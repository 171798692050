import React from "react";
import MenuComponent from "./MenuComponent/MenuComponent";
import "./OrderPageStyles.css"
import CartCardComponent from "../CartPage/CartCardComponent/CartCardComponent";
import { HiOutlineShoppingCart } from "react-icons/hi";
import CartComponent from "../CartPage/CartComponent/CartComponent";
import { useTheme } from "../../hooks/ThemeContext";
import { ToastContainer } from "react-toastify";

type Props = {
    cart: any[],
    setCart: React.Dispatch<React.SetStateAction<any[]>>,
    productData: any[],
    menuSettings: any[],
    additionalCharges: any[],
    testMode?: boolean,
    openingHours: any,
}


const OrderPage = (props: Props) => {

    const [currencySymbol, setCurrencySymbol] = React.useState("£");
    const containerRef = React.useRef<HTMLDivElement | null>(null);

    const { theme } = useTheme();

    const addToCart = (newEntry: any) => {
        
        let existingItem: any;

        for (let product of props.cart) {
            if (product.id === newEntry.id && JSON.stringify(product.additionalOptionIds) === JSON.stringify(newEntry.additionalOptionIds) && product.additionalRequests === newEntry.additionalRequests) {
                existingItem = product;
                existingItem.quantity += newEntry.quantity;
            }
        }

        if (existingItem) {
            props.setCart(prevCart => prevCart.map((p) => (p.id === existingItem.id && JSON.stringify(p.additionalOptionIds) === JSON.stringify(existingItem.additionalOptionIds) && p.additionalRequests === existingItem.additionalRequests) ? existingItem : p))
        }
        else {
            props.setCart(prevCart => [...prevCart, newEntry])
        }

    }



    return (
        <div style={{display: "flex", width: "100%", height: "100%"}}>
            <ToastContainer limit={1} style={{ zIndex: 9999000}} />
            <div className="menu-web-container" ref={containerRef} style={{height: "100%", color: theme.textColor, backgroundColor: theme.backgroundColor, flexGrow: 1, overflowX: "hidden"}}>
                <MenuComponent containerRef={containerRef} productData={props.productData} menuSettings={props.menuSettings} addToCart={addToCart} openingHours={props.openingHours}/>
            </div>
            
            <div className="cart-web-container" style={{width: "30%", minWidth: "300px", boxSizing: "border-box", backgroundColor: theme.cartBackgroundColor, userSelect: "none"}}>
                <CartComponent cart={props.cart} setCart={props.setCart} productData={props.productData} menuSettings={props.menuSettings} openingHours={props.openingHours} additionalCharges={props.additionalCharges} testMode={props.testMode || false}/>
            </div>
        </div>
    )

}

export default OrderPage;