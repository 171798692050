import React from "react";
import * as ReactIcons from 'react-icons/hi'



type Props = {
    buttonText: string,
    iconName: keyof typeof ReactIcons;
    url: string | string[],
    iconColor?: string,
    textColor: string,
    selectedColor: string,
    verticalView?: boolean;
    sleek?: boolean;
    hideInDesktopView?: boolean;
}

const SidebarButton = (props: Props) => {

    const IconComponent = ReactIcons[props.iconName];
    const [isSelected, setIsSelected] = React.useState(false);

    React.useEffect(() => {
        // console.log(window.location.pathname, props.url)
        if (typeof props.url === "string") {
            setIsSelected(props.url===window.location.pathname)
        }
        else {
            setIsSelected(props.url.includes(window.location.pathname));
        }
    }, [props.url])

    
    return (
        <div className={`sidebar-button ${props.hideInDesktopView ? "hide-in-desktop-view" : "show"} ${props.sleek ? "sleek" : "extended"} ${props.verticalView ? "full-button-width" : ""} ${isSelected ?  "sidebar-button-selected app-primary-color-selected" : "app-primary-color"}}`}  onClick={()=>{window.location.href= typeof(props.url) === "string" ? props.url : props.url[0] }} >
            <div>
                <div className={`button-icon ${props.sleek ? "sleek" : "extended"}`} style={{color: isSelected ? props.selectedColor : (props.iconColor || props.textColor)}}><IconComponent /></div>
                <span className={`button-text ${props.sleek ? "sleek" : "extended"}`} style={{color: isSelected ? props.textColor : props.textColor}}>{props.buttonText}</span>
            </div>
        </div>
    )
}

export default SidebarButton;