import React from 'react';
import './Spinner.css';

type Props = {
  color?: string,
}

const Spinner = (props: Props) => {
  return (
    <div className="spinner" style={{borderTopColor: props.color || "#3498db"}}></div>
  );
};

export default Spinner;