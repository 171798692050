import { useTheme } from "../../../hooks/ThemeContext"
import "./CategoryButtonComponentStyles.css"

type Props = {
    buttonText: string,
    isSelected?: boolean,
    onClick: any,
    reference?: any,
}

const CategoryButtonComponent = (props: Props) => {

    const { theme } = useTheme();

    return (
        <div ref={props.reference} className={`category-button ${props.isSelected ? "selected" : "not-selected"}`} onClick={props.onClick} style={{display: "flex", height: "100%", minWidth: "100px", marginLeft: "20px", flex: "1", backgroundColor: "", margin: "0", justifyContent: "center", alignItems: "center", borderBottom: props.isSelected ? `4px solid ${theme.categoryIndicatorColor}` : "", cursor: "pointer", boxSizing: "border-box"}}>
            <span>{props.buttonText}</span>
        </div>
    )
}

export default CategoryButtonComponent;