import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyACnLCTuF06LS7RbBwKBCTMpvsLCB2R4jQ",
  authDomain: "sultans-lounge.firebaseapp.com",
  projectId: "sultans-lounge",
  storageBucket: "sultans-lounge.appspot.com",
  messagingSenderId: "810647288432",
  appId: "1:810647288432:web:5ba23bcdad98b6be89d2b5",
  measurementId: "G-RJ8X41BXQ2"
};




// Initialize Firebase
// console.log("INITIALIZEING FIREBASE")
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app) as firebase.storage.Storage;

const firestore = getFirestore(app);

export { firestore, storage, app as default };

export const FIREBASE_APP = initializeApp(firebaseConfig);
export const FIREBASE_FIRESTORE = getFirestore(FIREBASE_APP);
