import React from "react";
import CategoryButtonComponent from "./CategoryButtonComponent";
import { useTheme } from "../../../hooks/ThemeContext";
import "./CategoryGroupComponentStyles.css"


type Props = {
    categoryData: { categoryId: string; categoryText: string }[];
    selectedCategory: string,
    setSelectedCategory?: any,
    scrollToCategory?: any,
    buttonsRef?: any,
    scrollToStart?: any,
    categoryContainerRef?: any,
    activeMenu: string,
}

const CategoryGroupComponent = (props: Props) => {

    const { theme } = useTheme();

    return (
        <div className="category-group-container" ref={props.categoryContainerRef} style={{display: "flex", overflowX: "scroll", flexDirection: "row", flexGrow: 0, justifyContent: "space-between", width: "inherit", height: "50px", borderBottom: `1px solid ${theme.borderColor}`, boxSizing: "border-box"}}>

            {props.categoryData.map((button, index) => (
                <CategoryButtonComponent reference={(el: any) => (props.buttonsRef.current[index] = el)} key={button.categoryId} buttonText={button.categoryText} isSelected={button.categoryId == props.selectedCategory} onClick={()=>{props.scrollToCategory(index); props.scrollToStart(index)}}/>
            ))}

        </div>
    )
}

export default CategoryGroupComponent;