import { collection, addDoc, getDocs, query, doc, getDoc, setDoc} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FIREBASE_APP, FIREBASE_FIRESTORE } from "../Firebase";
import { User } from "firebase/auth";
import React from "react";


class DataService {

    // public static uploadImage = async (uri: any, filename: string) => {
    //     try {
    //       const storage = getStorage(FIREBASE_APP); // Initialize Firebase Storage
            
    //       // Create a reference in the storage for this image
    //       const storageRef = ref(storage, filename);
      
    //       // Convert the local image URI to a blob
    //       const response = await fetch(uri);
    //       const blob = await response.blob();
      
    //       // Upload the image blob to Firebase Storage
    //       await uploadBytes(storageRef, blob);
      
    //       // Get the download URL of the uploaded image
    //       const downloadURL = await getDownloadURL(storageRef);
      
    //       console.log("Image uploaded successfully:", downloadURL);
    //       return downloadURL; // Return the URL of the uploaded image
    //     } catch (error) {
    //       console.error("Error uploading image: ", error);
    //       return null;
    //     }
    // };


    public static GetData = async (collection_name: string, document?: string, queryObj?: any, setObj?: any) => {
        const asyncKey = collection_name + "_" + document + "_" + JSON.stringify(queryObj);
        const localData = await localStorage.getItem(asyncKey);

        if (localData) {
            const parsedLocalData = JSON.parse(localData);
            setObj(parsedLocalData)
            const statusRef = doc(FIREBASE_FIRESTORE, collection_name, "status");
            const status = await getDoc(statusRef);
            let lastUpdate;

            if (status.exists()) {
                lastUpdate = status.data().updateTime;
            
                const lastRetreival = parsedLocalData.retrievalTime;

                console.log("Last update was: ", lastUpdate)
                console.log("Last retreival was: ", lastRetreival)

                if (new Date(lastUpdate) < new Date(lastRetreival)) {
                    console.log("Update not required!");
                    return parsedLocalData.data;
                }
            }
        }
        
        console.log("Getting data from server...")

        const collectionRef = collection(FIREBASE_FIRESTORE, collection_name);
        
        let docRef;
        let data;

        if (queryObj) {
            const q = query(collectionRef, queryObj);
            docRef = await getDocs(q);
        }
        else {
            if (!document) {
                docRef = await getDocs(collectionRef);
                data = docRef.docs.filter((x) => x.id !== "status").map((x) => x.data());
            }
            else {
                const ref = doc(FIREBASE_FIRESTORE, collection_name, document);
                docRef = await getDoc(ref);
                data = docRef.data();
            }
        }

        const currentTime = (new Date()).toUTCString();

        localStorage.setItem(asyncKey, JSON.stringify({retrievalTime: currentTime, data: data}))
    
        return data;
    }



    public static addData = async (collection_name: string, data: any, metadata?: any, document_name?: string) => {
        try {
            
            // for (let key in data) {
            //     if (typeof data[key] === "object") {
            //         let dataObj = data[key]
            //         if (dataObj && Object.keys(dataObj).includes("uri")) {
            //             let value = dataObj["uri"];
            //             if (value !== "") { 
            //                 console.log("Image has been uploaded: ", key);
            //                 const filename = `events/images/${Date.now()}_${Math.random().toString(36).substring(2)}`;
            //                 const downloadUrl = await this.uploadImage(value, filename);

            //                 data[key] = {"source": filename, "downloadUrl": downloadUrl, "uri": dataObj["uri"]}
            //             }
            //         }
            //     }
            // }

            if (metadata) {
                data["metadata"] = metadata;
            }

            const collectionRef = collection(FIREBASE_FIRESTORE, collection_name);
            const statusRef = doc(FIREBASE_FIRESTORE, collection_name, "status");
            const currentTime = (new Date()).toUTCString();
            const statusDocRef = await setDoc(statusRef, {updateTime: currentTime})

            let docRef;

            if (!document_name) {
                docRef = await addDoc(collectionRef, data);
                console.log("Document written: ", docRef.id);
            }
            else {
                const ref = doc(collectionRef, document_name);
                docRef = await setDoc(ref, data);
                console.log("Document written: ", document_name);
            }    
        }
        catch (error){
            console.log("Error: ", error)
        };
    }
      

}

export default DataService;