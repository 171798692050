import React from "react";
import "./SlideshowComponentStyles.css"

type Props = {
    height?: string,
}

const SlideshowComponent = (props: Props) => {
    // highly pissed rn. wasted time. could have been much more productive
    return (
        <div className="slideshow-container" style={{display: "flex", width: "100%", height: props.height || "35%", backgroundColor: "#0a1239", backgroundImage: "url(https://i.ibb.co/0cN5KbJ/f1.jpg)", backgroundSize: "cover", backgroundPosition: "center", borderRadius: "25px"}}>
            
            {/* <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "60%", padding: "10px"}}>
                <div>
                    <h1 className="heading-text" style={{color: "#ECD1C3", margin: "0"}}>ORDER ONLINE</h1>
                    <h1 className="heading-text" style={{color: "#ECD1C3", margin: "0"}}>AND</h1>
                    <h1 className="heading-text" style={{color: "#ECD1C3", margin: "0"}}>SKIP THE LINE</h1>
                    <br />
                </div>
                    <h2 className="secondary-text" style={{color: "#ECD1C3", margin: "0"}}>Get your food faster!</h2>
            </div> */}
            {/* <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "40%"}}>
                <img style={{width: "60%", borderTopRightRadius: "10px", borderBottomRightRadius: "10px"}} src={"https://i.ibb.co/zVtQpqL/feature-20241112025952-0-Problembo.jpg"}  />
                <img style={{width: "60%", borderTopRightRadius: "10px", borderBottomRightRadius: "10px"}} src={"https://i.ibb.co/SQCRnn0/transparent-logo.png"}  />
                <img style={{width: "100%", height: "100%", objectFit: "cover", borderTopRightRadius: "10px", borderBottomRightRadius: "10px"}} src={"https://www.thespruceeats.com/thmb/ao7czXh1afSBamtQnDUilsLD3uc=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/doner-kebab-recipe-4171703-hero-01-c14d8b7189df4685a50bae311317f831.jpg"}  />
                <img style={{width: "100%", height: "100%", objectFit: "cover", borderTopRightRadius: "10px", borderBottomRightRadius: "10px"}} src={"https://marinalock.com/cdn/shop/files/parallex.jpg?v=1721649070&width=1500"}  />
            </div> */}



        </div>
    )
}

export default SlideshowComponent;