import React, { useState } from "react";
import { useTheme } from "../../../../hooks/ThemeContext";
import { PaymentRequestButtonElement, useElements, useStripe } from "@stripe/react-stripe-js";
import CardDetails from "./CardDetails";
import { toast } from "react-toastify";
import StripeService from "../../../../service/StripeService";
import TimeService from "../../../../service/TimeService";
import ToastService from "../../../../service/ToastService";

type Props = {
    order: any,
    setCart: any,
    amount: number,
    formData: any,
    onBack: any,
    onSuccessReturn: any,

    paymentRequest: any,
    stripe: any,
    canMakePayment: boolean,
    skipMethodsScreen: boolean,
    updateQuantity: any,
    menuSettings: any,
    openingHours: any,
    testMode: boolean
}


const PaymentDetails = (props: Props) => {

    const { theme } = useTheme();
    const [loading, setLoading] = useState(false);
    const [payByCardScreen, setPayByCardScreen] = useState(false);
    const elements = useElements();

    const hitBack = (e: any) => {
      e.preventDefault();
      props.onBack();
    }


    React.useEffect(() => {

      const checkMenuItems = async () => {
        let allowedMenus = await TimeService.getAllowedMenus(props.menuSettings);
        let updatedCart = false;
  
        for (let orderItem of props.order) {
          let menuFound = false;
          for (let menuItem of orderItem.menus) {
              if (allowedMenus.includes(menuItem)) {
                  menuFound = true;
              }
          }
          if (menuFound == false) {
              updatedCart = true;
              props.updateQuantity(orderItem, 0);
              return false;
          }
        }
        return true;
      }

      if (props.paymentRequest) {

              props.paymentRequest.on('paymentmethod', async (ev: any) => {
                checkMenuItems().then(async (response) => {
                  if (response) {

                    console.log("processing express transaction...")
                    setLoading(true);
                    const result = await StripeService.expressCheckout(
                      props.stripe,
                      ev.paymentMethod.id,
                      props.order,
                      props.amount,
                      props.formData,
                      props.testMode
                    );
                    setLoading(false);
                
                    if (result.status === "SUCCESS") {
                      ev.complete('success')
                      localStorage.setItem(`order_${result.orderCode}`, JSON.stringify(props.order))
                      props.setCart([]);
                      props.onSuccessReturn();
                      ToastService.showToast("Payment Successful!", {
                        style: {
                            backgroundColor: "darkgreen", // Set the toast background color to green
                            color: "white", // White text for contrast
                        },
                      });
                      const newWindow = window.open(`/orderConfirmation?id=${result.orderCode}`, '_blank', 'width=600,height=800');
                      if (!newWindow) {
                        alert(`Your order was successful. Your order code is ${result.orderCode}. You will receive an email receipt.`)
                      }
                      
                    } else {
                      ev.complete('fail')
                      ToastService.showToast("Payment Failed!", {
                        style: {
                            backgroundColor: "darkred", // Set the toast background color to green
                            color: "white",           // White text for contrast
                        },
                      });
                      setLoading(false);
                    }
                  }
                  else {
                    ev.complete('fail')
                    ToastService.showToast(`Unavailable items have been removed from your cart. Please verify your cart before ordering!`, {
                      style: {
                          backgroundColor: "darkred", // Set the toast background color to green
                          color: "white",
                          minHeight: "80px",
                          borderRadius: "15px",
                      },
                    });
                    props.onSuccessReturn();
                    return;
                  }

                })
      
      
              })

      }


      


    }, [props.paymentRequest, props.stripe])

    


    const getPaymentMethodButtons = () => {
      
        return (
          <>
          <div
          style={{
            display: "flex",
            color: theme.cartTextColor,
            flexGrow: "1",
            flexDirection: "column",
            padding: "20px",
            boxSizing: "border-box",
            overflowY: "scroll"
          }}
        >
          <button
            style={{
              width: "50px",
              cursor: "pointer",
              backgroundColor: theme.cartButtonColor,
              color: theme.cartButtonTextColor,
              border: "none",
              borderRadius: "10px",
              padding: "5px",
            }}
            onClick={hitBack}
          >
            Back
          </button>
            <label style={{marginTop: "20px", marginBottom: "5px"}}>Payment Methods</label>
  
              <button
                  style={{
                    height: "40px",
                    marginTop: "10px",
                    width: "100%",
                    cursor: "pointer",
                    backgroundColor: theme.payButtonColor,
                    color: theme.payButtonTextColor,
                    border: "none",
                    borderRadius: "10px",
                  }}
                  onClick={()=>{setPayByCardScreen(true)}}
                >
                  Credit / Debit Card
                </button>
  
      
      
              {
                props.paymentRequest && props.canMakePayment &&
                <div style={{marginTop: "10px"}}>
                  <PaymentRequestButtonElement
                    options={{
                      paymentRequest: props.paymentRequest,
                      style: {
                        paymentRequestButton: {
                          theme: 'dark', // Choose your theme
                          height: '40px',
                        },
                      },
                    }}
                  />
                </div>
              }
              </div>
          </>
        )
      }



    if (!payByCardScreen && !props.skipMethodsScreen){
      console.log("SKIP METHOD SCREEN FALSE: ", props.skipMethodsScreen);
        return (
            <>
                { getPaymentMethodButtons() }
            </>
        )
    }
    else {
        return (
          <div
            style={{
              display: "flex",
              color: theme.cartTextColor,
              flexGrow: "1",
              flexDirection: "column",
              padding: "20px",
              boxSizing: "border-box",
              overflowY: "scroll"
            }}
          >

  
            <CardDetails stripe={props.stripe} elements={elements} order={props.order} setCart={props.setCart} amount={props.amount} formData={props.formData} onBack={()=>{!props.skipMethodsScreen ? setPayByCardScreen(false) : props.onBack()}} onSuccessReturn={()=>props.onSuccessReturn()} updateQuantity={props.updateQuantity} menuSettings={props.menuSettings} openingHours={props.openingHours} testMode={props.testMode}/>
  
            </div>
        )
      }
}

export default PaymentDetails;