import React from "react"
import "./CartComponentStyles.css"
import CartCardComponent from "../CartCardComponent/CartCardComponent"

type Props = {
    productData: any,
    cart: any,
    currencySymbol: string,
    updateQuantity?: any,
}

const CartCards = (props: Props) => {

    return (
        <>
        {props.productData.length > 0 && props.cart.length > 0 && props.cart.map((item: any, index: any) => {
            let mainProduct = props.productData.filter((product: any) => product.productId === item.id)[0]
            let additionalProducts = props.productData.filter((product: any) => item.additionalOptionIds.includes(product.productId))
            let totalPrice = mainProduct.productPrice;

            for (let subproduct of additionalProducts) {
                totalPrice += subproduct.productPrice;
            }

            if (item.quantity > 0) {
                return (
                    <div key={index}>
                        <CartCardComponent key={index} cartItem={item} mainProduct={mainProduct} additionalProducts={additionalProducts} currencySymbol={props.currencySymbol} totalPrice={totalPrice} quantity={item.quantity} additionalRequests={item.additionalRequests} updateQuantity={props.updateQuantity || null}/>
                    </div>
                )
            }

        })}
        </>
    )
}

export default CartCards;