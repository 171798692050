import React from "react";
import "./ProductCardComponentStyles.css"
import ReactModal from "react-modal";
import ProductCardAdditionalOptions from "./ProductCardAdditionalOptions";
import { useTheme } from "../../hooks/ThemeContext";

type Props = {
    productId: string,
    productName: string,
    productPrice: number,
    currencySymbol: string,
    menus: string[],
    activeMenu: string,
    productDescription?: string,
    productThumbnailUrl?: string,
    additionalOptions?: any[],
    addToCart: any,
    menuSettings: any[],
    openingHours: any
}

const ProductCardComponent = (props: Props) => {

    const { theme } = useTheme();

    const modalCustomStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: theme.cartBackgroundColor,
          color: theme.cartTextColor,
          padding: '20px',
          border: 'solid 1px black',
          borderRadius: '20px',
          width: '70svw',
          minWidth: '350px',
          maxWidth: '400px',
          maxHeight: '100svh',
          zIndex: '100000000',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: '100000000',
    
        }
    };



    const [showModal, setShowModal] = React.useState(false);

    return (
        <>
        <div className="product-card-component-container" style={{border: `1px solid ${theme.borderColor}`, backgroundColor: theme.cardColor}} onClick={()=>{setShowModal(true)}}>
            
            <div style={{display: "flex", flexGrow: "1", flexDirection: "column", textAlign: "left"}}>
                <div style={{display: "flex", flexGrow: "1", flexDirection: "column", textAlign: "left"}}>
                    <span style={{fontWeight: "bold"}}>{props.productName}</span>
                    <span style={{fontSize: "small"}}>{props.productDescription || ""}</span>

                </div>
                <br />
                <span style={{fontSize: "small", fontWeight: "bold", marginTop: "5px"}}>{props.currencySymbol + props.productPrice.toFixed(2)}</span>
            </div>

            {props.productThumbnailUrl && 
                <div style={{width: "100px", height: "100px", flexShrink: 0, marginLeft: "10px"}} >
                    <img style={{width: "100%", height: "100%", objectFit: "cover", borderRadius: "10px"}} src={props.productThumbnailUrl} />
                </div>
            }



        </div>

            <ReactModal isOpen={showModal} style={modalCustomStyles}>
                <button className="button primary" style={{float: "right", borderRadius: "5px", border: "none", padding: "10px", backgroundColor: theme.cartButtonColor, color: theme.cartButtonTextColor, cursor: "pointer"}} onClick={()=>{setShowModal(false)}}>Close</button>
                <ProductCardAdditionalOptions productId={props.productId} productName={props.productName} productPrice={props.productPrice} currencySymbol={props.currencySymbol} menus={props.menus} activeMenu={props.activeMenu} additionalOptions={props.additionalOptions} addToCart={props.addToCart} setModalOpen={setShowModal} menuSettings={props.menuSettings} openingHours={props.openingHours}/>

            </ReactModal>


        </>
    )
}

export default ProductCardComponent;