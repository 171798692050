import { BrowserRouter, Routes, Route } from "react-router-dom";
import RootPage from "./RootPage";
import React from "react";
import OrderPage from "./components/OrderPage/OrderPage";
import CartPage from "./components/CartPage/CartPage";
import MarinaLockHome from "./components/MarinaLockHome";
import DataService from "./service/DataService";
import RoutePageRoutes from "./RoutePageRoutes";


const App = () => {


  return (
    <>
        <BrowserRouter>

        <Routes>
            {/* <Route path="/" element={<SitesPage activeUserId={activeUserId} />} /> */}
            {/* <Route path="/home" element={<MarinaLockHome />} /> */}
            {/* <Route path="/" element={<MarinaLockHome setShowTopbar={setShowTopbar} setShowSidebar={setShowSidebar}/>} /> */}
            {/* <Route path="/" element={<MarinaLockHome />} /> */}
            <Route path="/test/*" element={<RoutePageRoutes testMode={true}/> } />
            <Route path="*" element={<RoutePageRoutes /> } />

        </Routes>

    
      </BrowserRouter>

    
    </>
  )


}

export default App;