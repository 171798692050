import React from "react";



const Terms = () => {

    return (
        <div style={{padding: "40px", paddingTop: "0px", overflowY: "scroll", textAlign: "left"}}>

            <h1>Terms and Conditions for Online Ordering</h1>

            <p>Welcome to <strong>Sultan's Lounge</strong>. By using our website to order food, you agree to comply with and be bound by the following terms and conditions. Please read these terms carefully before placing an order.</p>

            <h2>1. General Information</h2>
            <p>1.1. <strong>Sultan's Lounge</strong> operates the website and provides online food ordering services.</p>
            <p>1.2. By placing an order through our website, you confirm that you are legally capable of entering into binding contracts and that the information you provide is accurate and complete.</p>
            <p>1.3. These terms and conditions apply to all online orders made via our website.</p>

            <h2>2. Placing an Order</h2>
            <p>2.1. When placing an order, you agree to provide accurate and complete information, including your email address, phone number, and payment details (card information).</p>
            <p>2.2. Once your order is placed, you will receive an email confirmation. Please review the confirmation for accuracy.</p>
            <p>2.3. Orders cannot be canceled or modified after being placed, as they are immediately processed for preparation.</p>

            <h2>3. Payment</h2>
            <p>3.1. All payments must be made at the time of placing the order using a valid credit or debit card.</p>
            <p>3.2. <strong>Sultan's Lounge</strong> uses a secure payment gateway to process transactions, and your card details are encrypted for security purposes.</p>
            <p>3.3. By submitting your payment details, you confirm that you are the authorized cardholder or have permission to use the card for the transaction.</p>

            <h2>4. Data Privacy and Security</h2>
            <p>4.1. By placing an order, you agree to the collection and use of your personal information (email address, phone number, and card details) as outlined in our <a href="#">Privacy Policy</a>.</p>
            <p>4.2. Your information will only be used for processing your order and delivering the service. You can view our full privacy policy for more details on how we handle your data.</p>

            <h2>5. Promotional Emails</h2>
            <p>5.1. You have the option to opt-in to receive promotional emails from <strong>Sultan's Lounge</strong>. These emails may include information about new menu items, discounts, or special offers.</p>
            <p>5.2. You can unsubscribe from promotional emails at any time by clicking the "unsubscribe" link in the email or contacting us directly.</p>

            <h2>6. Liability</h2>
            <p>6.1. <strong>Sultan's Lounge</strong> will not be held responsible for any damages, including but not limited to, indirect, incidental, or consequential damages, arising out of the use of this website or the online ordering process.</p>
            <p>6.2. We make every effort to ensure that the website is accurate and up-to-date. However, we cannot guarantee that the website will be free from errors or interruptions.</p>

            <h2>7. Changes to Terms and Conditions</h2>
            <p>7.1. We reserve the right to update these terms and conditions at any time without notice. It is your responsibility to review them regularly.</p>
            <p>7.2. By continuing to use our website after changes are made, you agree to be bound by the updated terms.</p>

            <h2>8. Contact Information</h2>
            <p>If you have any questions or concerns regarding these terms, please contact us at:</p>
            <strong>Sultan's Lounge</strong>
            <ul>
                <li>Phone: 020 8847 5777</li>
                <li>Email: info@sultanslounge.co.uk</li>
            </ul>

        </div>
    )
}

export default Terms;