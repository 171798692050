import { FiHelpCircle } from "react-icons/fi"
import SidebarButton from "./SidebarButton"
import "../styles/DefaultSidebar.css"
import "../mobile_styles/DefaultSidebar.css"
import SignIn from "./SignIn"
import { useTheme } from "../hooks/ThemeContext"
import React from "react"

type Props = {
    sidebarPages: any,
    showSidebar: boolean;
    overlappingBar: boolean;
    setActiveUserId: any;
    mobileBottomBar?: boolean,
    sleekBar?: boolean
}

const DefaultSidebar = (props: Props) => {

    const { theme } = useTheme();

    return (
        <div style={props.overlappingBar ? {position: "fixed", "left": "0", top: "1px", zIndex: "1"} : {float: "left"}}>

            <div className={props.mobileBottomBar ? `bottom-bar ${props.sleekBar ? "sleek" : "extended"}` : (props.showSidebar ? `sidebar ${props.sleekBar ? "sleek" : "extended"} open` : `sidebar closed`)} style={{display: "flex", flexDirection: "column", height: "100%", color: theme.sidebarIconColor, borderRight: `1px solid ${theme.sidebarBorderColor}`, backgroundColor: theme.sidebarBackgroundColor || ""}}>
                {/* <div className="logo" style={{}}>
                    <h3 className="logo-text">STUDYSPACE</h3>
                    <h2 className="logo-text">PARTNERS</h2>
                    <h3 className="logo-text">CONSOLE</h3>
                </div> */}
                
                <div className={props.mobileBottomBar ? `sidebar-buttons-container ${props.sleekBar ? "sleek" : "extended"} mobile-bottom-bar` : `sidebar-buttons-container ${props.sleekBar ? "sleek" : "extended"}`} >
                    
                    {
                        props.sidebarPages.map((page: any, index: any) => {
                            return(
                                <SidebarButton key={index} buttonText={page.buttonText} url={page.url} iconName={page.iconName} textColor={theme.sidebarTextColor || "black"} selectedColor={theme.sidebarIconColorSelected || "blue"} iconColor={theme.sidebarIconColor} verticalView={!props.mobileBottomBar} sleek={props.sleekBar} hideInDesktopView={page.hideInDesktopView}/>
                            )
                        })


                    }

                    {/* <SidebarButton buttonText="Settings" url="/settings" iconName={"HiCog"} textColor={props.textColor || "black"} selectedColor={props.selectedColor || "blue"} iconColor={props.iconColor} verticalView={!props.mobileBottomBar} sleek={props.sleekBar} />
                    <SidebarButton buttonText="Support" url="/support" iconName={"HiOutlineInformationCircle"} textColor={props.textColor || "black"} selectedColor={props.selectedColor || "blue"} iconColor={props.iconColor} verticalView={!props.mobileBottomBar} sleek={props.sleekBar} /> */}
                </div>

                {/* <div style={{display: "flex", flexGrow: "1", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center"}}>
                    <div style={{marginTop: "auto", marginBottom: "20px"}}>
                        <SignIn setActiveUserId={props.setActiveUserId} />
                    </div>    
                </div> */}

            </div>
 
        </div>
    )

}

export default DefaultSidebar;