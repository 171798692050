import React from "react";



const PrivacyPolicy = () => {

    return (

        <div style={{padding: "40px", paddingTop: "0px", overflowY: "scroll", textAlign: "left"}}>

            <h1>Privacy Policy</h1>

            <p>Your privacy is important to us at <strong>Sultan's Lounge</strong>. This Privacy Policy explains how we collect, use, and protect your personal information when you use our website and place orders online.</p>

            <h2>1. Information We Collect</h2>
            <p>When you place an order on our website, we collect the following personal information:</p>
            <ul>
                <li><strong>Personal Identification Information:</strong> Your name, email address, and phone number.</li>
                <li><strong>Payment Information:</strong> Credit or debit card details used for processing payments. We do not store your card information; it is securely handled by our payment processor.</li>
                <li><strong>Order Details:</strong> Information about the items you order from our menu.</li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <p>We use your personal information for the following purposes:</p>
            <ul>
                <li><strong>To process your order:</strong> We use your personal and payment information to fulfill and confirm your order.</li>
                <li><strong>To communicate with you:</strong> We may contact you via email or phone to provide updates on your order or respond to your inquiries.</li>
                <li><strong>To improve our services:</strong> We analyze customer data to enhance the user experience on our website and improve the quality of our service.</li>
                <li><strong>For marketing (if opted-in):</strong> If you have opted in to receive promotional emails, we will use your email address to send you news, special offers, and promotions. You can opt out at any time.</li>
            </ul>

            <h2>3. How We Protect Your Information</h2>
            <p>We are committed to ensuring that your information is secure. To prevent unauthorized access or disclosure, we have implemented appropriate physical, electronic, and managerial procedures to safeguard the information we collect online.</p>
            <p>We use a secure payment gateway to process your transactions, and all payment information is encrypted.</p>

            <h2>4. Sharing Your Information</h2>
            <p>We do not sell, trade, or rent your personal information to third parties. However, we may share your information with trusted third parties who help us operate our website, process payments, or provide customer service, provided they agree to keep this information confidential.</p>
            <p>We may also disclose your personal information if required by law or if we believe that such action is necessary to comply with a legal obligation or protect the rights, property, or safety of **Sultan's Lounge** and others.</p>

            <h2>5. Cookies</h2>
            <p>Our website may use cookies to enhance your browsing experience. Cookies are small files placed on your device that help us remember your preferences and provide a personalized experience. You can choose to disable cookies through your browser settings, though some features of the website may not function properly if cookies are disabled.</p>

            <h2>6. Your Rights</h2>
            <p>You have the right to access, correct, or delete your personal information at any time. If you would like to review or update your data, please contact us at the details provided below.</p>
            <p>If you opt into receiving promotional emails, you can unsubscribe at any time by clicking the "unsubscribe" link in the email or by contacting us directly.</p>

            <h2>7. Changes to This Privacy Policy</h2>
            <p>We reserve the right to update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date will be revised accordingly. It is your responsibility to review this policy periodically.</p>

            <h2>8. Contact Information</h2>
            <p>If you have any questions or concerns regarding this Privacy Policy, please contact us at:</p>
            
            <strong>Sultan's Lounge</strong>
            <ul>
                <li>Phone: 020 8847 5777</li>
                <li>Email: info@sultanslounge.co.uk</li>
            </ul>

        </div>
    )
}

export default PrivacyPolicy;