import React from "react";
import CategoryGroupComponent from "../CategoryGroupComponent/CategoryGroupComponent";
import ProductCardComponent from "../../ProductCardComponent/ProductCardComponent";
import { useTheme } from "../../../hooks/ThemeContext";
import TimeService from "../../../service/TimeService";


type Props = {
    addToCart: any,
    productData: any[],
    menuSettings: any[],
    containerRef?: any,
    menuLoading?: boolean,
    setMenuLoading?: any,
    openingHours: any
}

const CatalogueComponent = (props: Props) => {

    const [searchValue, setSearchValue] = React.useState("");
    const [activeCategory, setActiveCategory] = React.useState<number>(0);
    const [activeMenu, setActiveMenu] = React.useState("");
    const [categoryData, setCategoryData] = React.useState<any[]>([]);
    const categoriesRef = React.useRef<(HTMLDivElement | null)[]>([]); // Add typing for categoriesRef
    const categoryContainerRef = React.useRef<HTMLDivElement | null>(null);
    const buttonsRef = React.useRef<any[]>([]);

    const { theme } = useTheme();


    React.useEffect(() => {

        if (activeMenu !== ""){
            let uniqueCategoriesArray = Array.from(new Set(props.productData.filter((x) => x.menus.includes(activeMenu)).map(item => item.category)));

            const itemsToMove = ["Desserts", "Kids"];
            const filteredArray = uniqueCategoriesArray.filter(item => !itemsToMove.includes(item));
          
            // Check if "Desserts" and/or "Kids" are in the original array
            const desserts = uniqueCategoriesArray.includes("Desserts") ? ["Desserts"] : [];
            const kids = uniqueCategoriesArray.includes("Kids") ? ["Kids"] : [];

            uniqueCategoriesArray = [...filteredArray, ...desserts, ...kids]

            const data = uniqueCategoriesArray.map(category => ({
                categoryId: category,
                categoryText: category
            }));

            setCategoryData(data)
        }

    }, [activeMenu, props.productData])


    React.useEffect(() => {

        const handleMenuSettings = async () => {

            if (props.menuSettings) {
                for (let menuItem of props.menuSettings) {

                    if (menuItem.enabled){
                        let menuActive = await TimeService.checkActiveMenu(props.menuSettings, menuItem.menuName);

                        if (menuActive) {
                            setActiveMenu(menuItem.menuName)
                            props.setMenuLoading(false);
                            break;
                        }
                    }
                }
                if (!activeMenu && props.menuSettings.length > 0) {

                    for (let menuItem of props.menuSettings){
                        if (menuItem.enabled) {
                            setActiveMenu(menuItem.menuName);
                            props.setMenuLoading(false);
                            break;
                        }
                    }


                }
            }
        }

        handleMenuSettings();

    }, [props.menuSettings])


    const scrollToCategory = (index: number) => {
        const categoryElement = categoriesRef.current[index];
        const scrollContainer = props.containerRef.current;
        if (categoryElement && scrollContainer) {
        //   const containerTop = scrollContainer.getBoundingClientRect().top;
        //   const categoryTop = categoryElement.getBoundingClientRect().top;
      
          // Scroll to the element's position relative to the scroll container
          scrollContainer.scrollTo({
            top: categoryElement.offsetTop - scrollContainer.offsetTop - 190,
            behavior: "smooth",
          });
        }
    };

    React.useEffect(() => {
        const scrollContainer = props.containerRef.current;
    
        // Ensure the container exists before adding event listeners
        if (scrollContainer) {
          const handleScroll = () => {
            const containerTop = scrollContainer.getBoundingClientRect().top;
    
            // Determine which category is in view
            categoriesRef.current.forEach((ref, index) => {
                if (ref) {
                    const categoryTop = ref.getBoundingClientRect().top - containerTop;
                    if (categoryTop >= 0 && categoryTop < window.innerHeight / 2) {
                      setActiveCategory(index);
                      scrollToStart(index);
                    }
                }
            });
          };
    
          scrollContainer.addEventListener("scroll", handleScroll);
    
          // Cleanup event listener
          return () => {
            scrollContainer.removeEventListener("scroll", handleScroll);
          };
        }
    }, [props.containerRef]);


    const scrollToStart = (index: number) => {

        if (buttonsRef.current[index]) {

            const scrollContainer = categoryContainerRef.current;

            if (scrollContainer) {

                const categoryPosition = buttonsRef.current[index].getBoundingClientRect().left - scrollContainer.getBoundingClientRect().left;
                scrollContainer.scrollTo({
                    left: categoryPosition + scrollContainer.scrollLeft,
                    behavior: "smooth",
                });
            }
        }
    };

    if (!props.menuSettings) {
        return <></>
    }

    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%", height: "100%", marginBottom: "30px"}}>
            <div style={{position: "sticky", top: "0", backgroundColor: theme.backgroundColor, zIndex: 99999}}>
                <div style={{width: "100%", border: `1px solid ${theme.borderColor}`, borderRadius: "30px", marginTop: "20px", marginBottom: "0px"}}>
                    { props.menuSettings &&
                        <select value={activeMenu} onChange={(e) => setActiveMenu(e.target.value)} style={{width: "95%", height: "40px", lineHeight: "40px", paddingLeft: "20px", color: theme.textColor, border: "none", backgroundColor: "transparent", backgroundImage: "none", outline: "none", boxSizing: "border-box"}} >
                            { 
                                props.menuSettings.filter((x) => x.enabled).sort((a, b) => a.order - b.order).map((item, index) => {
                                    return (
                                        <option key={index} value={item.menuName} style={{textAlign: "center"}}>{item.menuName}</option>
                                    )
                                })
                            }
                        </select>
                    }
                </div>
                <input placeholder="What would you like to eat?" onChange={(e) => {setSearchValue(e.target.value)}} style={{width: "100%", height: "40px", lineHeight: "40px", paddingLeft: "20px", border: `1px solid ${theme.borderColor}`, marginTop: "10px", marginBottom: "10px", backgroundColor: theme.backgroundColor, color: theme.textColor, borderRadius: "30px", boxSizing: "border-box"}} />
                {categoryData.length>0 && <CategoryGroupComponent categoryContainerRef={categoryContainerRef} activeMenu={activeMenu} buttonsRef={buttonsRef} scrollToStart={scrollToStart} categoryData={categoryData} selectedCategory={categoryData[activeCategory].categoryId} scrollToCategory={scrollToCategory} />
                }
            </div>


            <div style={{width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-around", padding: "1%", boxSizing: "border-box"}}>

            {categoryData.map((category, index) => {


            if (props.productData.filter((p) => (p.productName.toLowerCase().includes(searchValue.toLowerCase())) && (p.productType !== "additional") && (p.category === category.categoryId)).length !== 0){
            
                return (
                    <div key={index} style={{display:"contents", width: "100%"}}>
                        <div key={index} className={"num-" + index} ref={(el) => (categoriesRef.current[index] = el)} style={{width: "100%", textAlign: "left"}}><p key={index} style={{fontWeight: "500", padding: "10px", marginTop: "10px", marginBottom: "10px", borderBottom: `1px solid ${theme.borderColor}`, borderTop: `1px solid ${theme.borderColor}`, textAlign: "center"}}>{category.categoryText}</p></div>
                        {props.productData.filter((p) => (p.productName.toLowerCase().includes(searchValue.toLowerCase())) && (p.productType !== "additional") && (p.category === category.categoryId) && (p.menus.includes(activeMenu))).map((product, index) => (
                            <ProductCardComponent key={product.productId} productId={product.productId} productName={product.productName} productDescription={product.productDescription} productPrice={product.productPrice} currencySymbol="£" menus={product.menus} activeMenu={activeMenu} additionalOptions={props.productData.filter((p) => product.additionalOptions && product.additionalOptions.includes(p.productId))} addToCart={props.addToCart} productThumbnailUrl={product.productThumbnailUrl} menuSettings={props.menuSettings} openingHours={props.openingHours}/>
                        ))}
                    </div>                        
                )
            }
            else {
                return <></>
            }
            })}

            </div>







            


        </div>
    )
}

export default CatalogueComponent;