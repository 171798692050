  
  import { useTheme } from "../../../hooks/ThemeContext";
  import React, { useState } from "react";
  import 'react-toastify/dist/ReactToastify.css';
  import PaymentDetails from "./PaymentOptions/PaymentDetails";
import { useStripe } from "@stripe/react-stripe-js";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import TimeService from "../../../service/TimeService";
import ToastService from "../../../service/ToastService";
  
  type Props = {
    order: any;
    amount: number;
    onBack?: any;
    setCart: any;
    menuSettings: any[],
    updateQuantity: any,
    openingHours: any,
    testMode: boolean
  };
  
  const PaymentDetailsComponent = (props: Props) => {

    const { theme } = useTheme();
    const [showPaymentMethods, setShowPaymentMethods] = useState(false);

    const [canMakePayment, setCanMakePayment] = useState<boolean | null>(null); // State to track Express availability
    const [paymentRequest, setPaymentRequest] = useState<any>(null);
    const stripe = useStripe();

    React.useEffect(() => {
      const checkCanMakePayment = async () => {
          if (!stripe) return;

          const pr = stripe.paymentRequest({
              country: 'GB', // Your country code
              currency: 'gbp', // Your currency code
              total: {
                  label: 'Total',
                  amount: Math.round(props.amount*100),
              },
              requestPayerName: true,
              requestPayerEmail: true,
              // You can also specify additional options here, like `requestPayerName: true`
          });

          const result = await pr.canMakePayment();
          setPaymentRequest(pr);
          setCanMakePayment(!!result); // Set state to true if Apple Pay is available
      };

      checkCanMakePayment();
  }, [stripe, props.amount]);

    
    // Form state for validation
    const [formData, setFormData] = useState({
      name: "",
      phone: "",
      email: "",
    });

    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [agreeToMarketing, setAgreeToMarketing] = useState(false);
    const [formErrors, setFormErrors] = useState<string[]>([]); // Array of error messages

        // Validate personal info fields
        const validatePForm = () => {
          let errors: string[] = [];
          let valid = true;
      
          if (!formData.name) {
            errors.push("Name is required.");
            valid = false;
          }
      
          if (!formData.phone || !/^\d{10,15}$/.test(formData.phone)) {
            errors.push("Enter a valid phone number.");
            valid = false;
          }
      
          if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            errors.push("Enter a valid email address.");
            valid = false;
          }
    
          // Check GDPR
          if (!agreeToTerms) {
            errors.push("Agreement to terms is required.");
            valid = false;
          }
      
          setFormErrors(errors);
          return valid;
        };
  
    // Validate personal info fields
    

    const onPersonalInfoCheck = async () => {

      if (props.order.length > 0) {

        let allowedMenus = await TimeService.getAllowedMenus(props.menuSettings);
        let updatedCart = false;

        for (let orderItem of props.order) {
          let menuFound = false;
          for (let menuItem of orderItem.menus) {
              if (allowedMenus.includes(menuItem)) {
                  menuFound = true;
              }
          }
          if (menuFound == false) {
              updatedCart = true;
              props.updateQuantity(orderItem, 0);
          }
        }
        if (!updatedCart) {
          if (!validatePForm()) {
            return;
          }
          setShowPaymentMethods(true);
        }
        else {
            props.onBack();
            ToastService.showToast(`Unavailable items have been removed from your cart. Please check cart before proceeding!`, {
                style: {
                    backgroundColor: "darkred", // Set the toast background color to green
                    color: "white",
                    minHeight: "80px",
                    borderRadius: "15px",
                },
            });
        }
    }

    }
  
    
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    };

    const hitBack = (e: any) => {
      e.preventDefault();
      props.onBack();
    }
    
  
    if (!showPaymentMethods){
      return (
        <div
          style={{
            display: "flex",
            color: theme.cartTextColor,
            flexGrow: "1",
            flexDirection: "column",
            padding: "20px",
            boxSizing: "border-box",
            overflowY: "scroll"
          }}
        >
          <button
            style={{
              width: "50px",
              cursor: "pointer",
              backgroundColor: theme.cartButtonColor,
              color: theme.cartButtonTextColor,
              border: "none",
              borderRadius: "10px",
              padding: "5px",
            }}
            onClick={hitBack}
          >
            Back
          </button>
    
          <div style={{ display: "flex", flexDirection: "column", padding: "10px", marginTop: "0px" }}>
            <p style={{ fontWeight: "bold" }}>Personal Info</p>
    
            <label style={{marginTop: "5px"}}>Name</label>
            <input
              name="name"
              value={formData.name}
              onChange={handleChange}
              style={{
                height: "20px",
                marginTop: "5px",
                padding: "5px",
                border: "1px solid grey",
                borderRadius: "10px",
                background: "none",
                color: theme.cartTextColor,
              }}
            />
    
            <label style={{marginTop: "10px"}}>Phone Number</label>
            <input
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              style={{
                height: "20px",
                marginTop: "5px",
                padding: "5px",
                border: "1px solid grey",
                borderRadius: "10px",
                background: "none",
                color: theme.cartTextColor,
              }}
            />
    
            <label style={{marginTop: "10px"}}>Email Address</label>
            <input
              name="email"
              value={formData.email}
              onChange={handleChange}
              style={{
                height: "20px",
                marginTop: "5px",
                padding: "5px",
                border: "1px solid grey",
                borderRadius: "10px",
                background: "none",
                color: theme.cartTextColor,
              }}
            />

          <div style={{ marginTop: "20px", fontSize: "12px"  }}>
            <input
              type="checkbox"
              id="agreeToTerms"
              checked={agreeToTerms}
              onChange={(e) => setAgreeToTerms(e.target.checked)}
            />
            <span style={{ marginLeft: "5px"}}>
              I agree to the <a href="/terms" target="_blank" rel="noopener noreferrer" style={{color: "lightblue"}}>Terms of Service</a>
              &nbsp;and <a href="/privacyPolicy" target="_blank" rel="noopener noreferrer" style={{color: "lightblue"}}>Privacy Policy</a>
            </span>
          </div>

          <div style={{ marginBottom: "20px", fontSize: "12px"  }}>
            <input
              type="checkbox"
              id="marketing"
              checked={agreeToMarketing}
              onChange={(e) => setAgreeToMarketing(e.target.checked)}
            />
            <span style={{ marginLeft: "5px" }}>
              I agree to receiving promotions and offers by email
            </span>
          </div>


          {
            !showPaymentMethods &&

            <button
              style={{
                height: "40px",
                marginTop: "10px",
                width: "100%",
                cursor: "pointer",
                backgroundColor: theme.payButtonColor,
                color: theme.payButtonTextColor,
                border: "none",
                borderRadius: "10px",
              }}
              onClick={onPersonalInfoCheck}
            >
              Next
            </button>


          }
          

            {/* <ExpressCheckoutElement onConfirm={onPaymentSubmit} options={{paymentMethods: {applePay: 'always', googlePay: 'always'}}}/> */}


    
            {/* Error list */}
            {formErrors.length > 0 && (
              <div style={{ color: "red" }}>
                <ul>
                  {formErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
    
            
          </div>
        </div>
      );
    }
    else {
      if (canMakePayment != null && paymentRequest) {
        return (
            <PaymentDetails stripe={stripe} paymentRequest={paymentRequest} canMakePayment={canMakePayment} order={props.order} setCart={props.setCart} amount={props.amount} formData={formData} onBack={() => setShowPaymentMethods(false)} onSuccessReturn={()=>props.onBack()} skipMethodsScreen={canMakePayment == false} updateQuantity={props.updateQuantity} menuSettings={props.menuSettings} openingHours={props.openingHours} testMode={props.testMode}/>
        )
      }
      else {
        return (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
              <ClipLoader color="#3498db" loading={true} size={20} />
          </div>
        )
      }
    }
  };
  
  export default PaymentDetailsComponent;
  