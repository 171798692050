import { HiOutlinePlusCircle, HiOutlineTrash } from "react-icons/hi";
import { HiOutlineMinusCircle } from "react-icons/hi";
import "./CartCardComponentStyles.css"
import { useTheme } from "../../../hooks/ThemeContext";
import React from "react";

type Props = {
    currencySymbol: string,
    cartItem: any,
    mainProduct: any,
    additionalProducts?: any,
    totalPrice: number,
    quantity: number,
    additionalRequests?: string,
    updateQuantity?: any,
}

const CartCardComponent = (props: Props) => {

    const { theme } = useTheme();

    return (
        <div style={{marginTop: "10px", backgroundColor: theme.cartCardColor, color: theme.cartTextColor, borderRadius: "15px", userSelect: "none"}}>

            { props.updateQuantity && <div style={{display: "flex", flexGrow: "1", flexDirection: "row", textAlign: "left", marginBottom: "1px", alignItems: "center", padding: "10px"}}>
                
                <div className="product-quantity-buttons">
                    <span onClick={() => {if (props.quantity > 1) props.updateQuantity(props.cartItem, props.quantity - 1)}} className={`cart-icon ${(props.quantity < 2) ? "disabled" : ""}`} >
                        <HiOutlineMinusCircle />
                    </span>
                    <span className="cart-quantity">{props.quantity}</span>
                    <span onClick={() => {props.updateQuantity(props.cartItem, props.quantity + 1)}} className="cart-icon">
                        <HiOutlinePlusCircle />
                    </span>
                </div>

                <div className="product-control-buttons">
                    <span onClick={() => {props.updateQuantity(props.cartItem, 0)}} className="cart-icon trash"><HiOutlineTrash /></span>
                </div>
            </div> }

            { !props.updateQuantity && <div style={{paddingTop: "20px"}} />}


        <div style={{border: "none", width: "100%", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px", boxSizing: "border-box"}} >
        
            
            <div style={{display: "flex", flexGrow: "1", flexDirection: "row", textAlign: "left"}}>
                <div style={{display: "flex", flexGrow: "1", flexDirection: "column", textAlign: "left"}}>
                    <span style={{fontWeight: "bold"}}> {props.mainProduct.productName}</span>

                </div>
                <br />
                <span style={{fontSize: "small", fontWeight: "bold"}}>{props.currencySymbol + (Math.round(props.quantity * props.totalPrice * 100) / 100).toFixed(2)}</span>
            </div>

            <div style={{display: "flex", flexGrow: "1", flexDirection: "row", textAlign: "left", marginTop: "8px", marginLeft: "10px"}}>
                <div style={{display: "flex", flexGrow: "1", flexDirection: "column", textAlign: "left"}}>
                    <span style={{fontSize: "small"}}>Base ({props.currencySymbol + (Math.round(props.mainProduct.productPrice * 100) / 100).toFixed(2)})</span>

                </div>
                <br />
                <span style={{fontSize: "small"}}>{props.currencySymbol + (Math.round(props.quantity * props.mainProduct.productPrice * 100) / 100).toFixed(2)}</span>
            </div>

            {props.additionalProducts.map((product: any, index: any) => {

                if (product.quantity > 0 || product.productType === "additional"){
                    return (
                        <div key={index} style={{display: "flex", flexGrow: "1", flexDirection: "row", textAlign: "left", marginTop: "8px", marginLeft: "10px"}}>
                            <div style={{display: "flex", flexGrow: "1", flexDirection: "column", textAlign: "left"}}>
                                <span style={{fontSize: "small"}}>{product.productName} ({props.currencySymbol + (Math.round(product.productPrice * 100) / 100).toFixed(2)})</span>

                            </div>
                            <br />
                            <span style={{fontSize: "small"}}>{props.currencySymbol + (Math.round(props.quantity * product.productPrice * 100) / 100).toFixed(2)}</span>
                        </div>
                    )
                }
            })}

            { props.additionalRequests && 

                <div style={{display: "flex", flexGrow: "1", flexDirection: "row", textAlign: "left", marginTop: "8px", marginLeft: "10px", marginRight: "10px"}}>
                    <div style={{display: "flex", flexGrow: "1", flexDirection: "column", textAlign: "left"}}>
                        <span style={{fontWeight: 800, fontSize: "small", marginBottom: "5px"}}>Additional Requests</span>
                        <span style={{fontSize: "small"}}>{props.additionalRequests}</span> 

                    </div>
                </div>
            
            }

            { !props.updateQuantity && <div style={{paddingBottom: "10px"}} />}


        </div>

        </div>
    )
}

export default CartCardComponent;