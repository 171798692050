import { Route, Routes } from "react-router-dom";
import RootPage from "./RootPage";
import OrderPage from "./components/OrderPage/OrderPage";
import CartPage from "./components/CartPage/CartPage";
import React from "react";
import DataService from "./service/DataService";
import { ThemeProvider } from "./hooks/ThemeContext";
import OrderConfirmationPage from "./components/CartPage/OrderConfirmationPage";
import Terms from "./components/Legal/Terms";
import PrivacyPolicy from "./components/Legal/PrivacyPolicy";

type Props = {
  testMode?: boolean
}


const myData = {
  "openingHours": {
      "monday": {
        "start": 0,
        "end": 1000
      },
      "tuesday": {
        "start": 0,
        "end": 1000
      },
      "wednesday": {
        "start": 0,
        "end": 1000
      },
      "thursday": {
        "start": 0,
        "end": 1000
      },
      "friday": {
        "start": 0,
        "end": 1000
      },
      "saturday": {
        "start": 0,
        "end": 1000
      },
      "sunday": {
        "start": 0,
        "end": 1000
      }
  },
  "menuSettings": [
    {"menuName": "Breakfast Menu",
      "order": 1,
      "timings": {
        "monday": {
          "start": 0,
          "end": 300
        },
        "saturday": {
          "start": 0,
          "end": 300
        }
      }
    },
    {"menuName": "Main Menu",
      "order": 2,
      "timings": {
        "monday": {
          "start": 0,
          "end": 2000
        },
        "saturday": {
          "start": 0,
          "end": 2000
        }
      }
    },
    {"menuName": "Kids Menu",
      "order": 3,
      "timings": {
        "monday": {
          "start": 0,
          "end": 2000
        },
        "saturday": {
          "start": 0,
          "end": 2000
        }
      }
    }
  ],
  "additionalCharges": [
    { name: "Platform Fee",
      amount: 2,
      operation: "%",
      description: "This fee helps cover the operational costs for this platform",
      "platformCharge": true
    }
  ],
  "menu": [
    {
        "productName": "Hummus",
        "productId": "001",
        "category": "Starters",
        "productThumbnailUrl": "",
        "productDescription": "Blended chickpeas with tahini paste, fresh garlic, lemon and extra virgin olive oil",
        "productPrice": 5.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Cacik",
        "productId": "002",
        "category": "Starters",
        "productThumbnailUrl": "",
        "productDescription": "Cucumber dip with creamy yogurt",
        "productPrice": 5.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Patlıcan Soslu",
        "productId": "003",
        "category": "Starters",
        "productThumbnailUrl": "",
        "productDescription": "Aubergine, green and red pepper served in a special tomato sauce",
        "productPrice": 6.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Tabule",
        "productId": "004",
        "category": "Starters",
        "productThumbnailUrl": "",
        "productDescription": "A mixture of parsley, tomatoes, peppers, spring onion and olive oil",
        "productPrice": 5.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Grilled Halloumi",
        "productId": "005",
        "category": "Starters",
        "productThumbnailUrl": "",
        "productDescription": "Grilled halloumi (Goats cheese from Cyprus)",
        "productPrice": 7.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Sucuk Izgara",
        "productId": "006",
        "category": "Starters",
        "productThumbnailUrl": "",
        "productDescription": "Delicious garlic sausages, charcoal grilled",
        "productPrice": 7.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Falafel",
        "productId": "007",
        "category": "Starters",
        "productThumbnailUrl": "",
        "productDescription": "A blend of chickpeas, broad beans, leeks, coriander, garlic and herbs",
        "productPrice": 7.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Muska Borek",
        "productId": "008",
        "category": "Starters",
        "productThumbnailUrl": "",
        "productDescription": "Deep fried filo pastry rolls stuffed with feta cheese, mint and herbs",
        "productPrice": 7.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Hummus Kavurma",
        "productId": "009",
        "category": "Starters",
        "productThumbnailUrl": "",
        "productDescription": "Traditional hummus topped with fine pan fried diced lamb",
        "productPrice": 8.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Kalamar",
        "productId": "010",
        "category": "Starters",
        "productThumbnailUrl": "",
        "productDescription": "Squid rings marinated & coated in light butter served with tartar sauce",
        "productPrice": 8.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Grilled Prawns",
        "productId": "011",
        "category": "Starters",
        "productThumbnailUrl": "",
        "productDescription": "6 Prawns, charcoal grilled served on a bed of mixed salad",
        "productPrice": 11.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Mixed Mezze",
        "productId": "012",
        "category": "Starters",
        "productThumbnailUrl": "",
        "productDescription": "A Selection of 6 mezzes, (Hummus Kavurma, Cacik, Halloumi, Tabule, Muska Borek and Patlican Soslu)",
        "productPrice": 28.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Chicken Shish Kebab",
        "productId": "013",
        "category": "Charcoal Grill",
        "productThumbnailUrl": "",
        "productDescription": "Marinated cubes of chicken breast, charcoal grilled, served with rice & mixed salad",
        "productPrice": 17.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Lamb Shish Kebab",
        "productId": "014",
        "category": "Charcoal Grill",
        "productThumbnailUrl": "",
        "productDescription": "Marinated cubes of lamb, charcoal grilled, served with rice & mixed salad",
        "productPrice": 19.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Iskender Kebab",
        "productId": "015",
        "category": "Charcoal Grill",
        "productThumbnailUrl": "",
        "productDescription": "Lamb shish, presented on a bed of crispy bread dressed in yogurt, Anatolian sauce & butter",
        "productPrice": 22.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Adana Kofte Kebab",
        "productId": "016",
        "category": "Charcoal Grill",
        "productThumbnailUrl": "",
        "productDescription": "Minced lamb blended with spices & herbs charcoal grilled served with rice & mixed salad",
        "productPrice": 18.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Lamb Chops",
        "productId": "017",
        "category": "Charcoal Grill",
        "productThumbnailUrl": "",
        "productDescription": "Best end-of-lamb cutlets with grilled tomatoes & rice",
        "productPrice": 24.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Lamb Ribs",
        "productId": "018",
        "category": "Charcoal Grill",
        "productThumbnailUrl": "",
        "productDescription": "Lamb spare ribs, charcoal grilled, served with rice & mixed salad",
        "productPrice": 22.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Chicken Wings",
        "productId": "019",
        "category": "Charcoal Grill",
        "productThumbnailUrl": "",
        "productDescription": "Marinated chicken wings, charcoal-grilled, served with rice & mixed salad",
        "productPrice": 16.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Mixed Shish",
        "productId": "020",
        "category": "Charcoal Grill",
        "productThumbnailUrl": "",
        "productDescription": "Marinated cubes of chicken & lamb served with rice & mixed salad",
        "productPrice": 18.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Mixed Grill",
        "productId": "021",
        "category": "Charcoal Grill",
        "productThumbnailUrl": "",
        "productDescription": "Selection of BBQ dishes, chicken, lamb, kofte, wings served with rice and mixed salad",
        "productPrice": 26.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Sirloin Steak",
        "productId": "022",
        "category": "Charcoal Grill",
        "productThumbnailUrl": "",
        "productDescription": "Premium cut steak, charcoal-grilled, served with chips, salad and creamy mash potatoes",
        "productPrice": 27.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Sultans Grand Platter",
        "productId": "023",
        "category": "Charcoal Grill",
        "productThumbnailUrl": "",
        "productDescription": "A selection of Sultans grilled meats, Adana kofte, chicken wings, chicken shish, lamb shish, lamb ribs served with rice & salad, suitable for 4-5 people",
        "productPrice": 94.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Grilled Sea Bass",
        "productId": "024",
        "category": "Seafood",
        "productThumbnailUrl": "",
        "productDescription": "Fresh sea bass marinated & barbecued, served with chips & mixed salad",
        "productPrice": 27.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Salmon Sis",
        "productId": "025",
        "category": "Seafood",
        "productThumbnailUrl": "",
        "productDescription": "Fresh Salmon steaks marinated & barbecued, served with rice & mixed salad",
        "productPrice": 24.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Falafel",
        "productId": "026",
        "category": "Vegetarian",
        "productThumbnailUrl": "",
        "productDescription": "Deep fried chickpeas with herbs, crispy on the outside and tender on the inside served with hummus and a choice of mixed salad",
        "productPrice": 14.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Vegetarian Kebab",
        "productId": "027",
        "category": "Vegetarian",
        "productThumbnailUrl": "",
        "productDescription": "Mixed vegetables over the chargrill served with rice",
        "productPrice": 11.99,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Baklava",
        "productId": "028",
        "category": "Desserts",
        "productThumbnailUrl": "",
        "productDescription": "Sweet layered pastry dessert with nuts and honey, a traditional Mediterranean delight",
        "productPrice": 6.90,
        "menus": ["Main Menu"]
    },
    {
        "productName": "Chicken Wings",
        "productId": "029",
        "category": "Kids",
        "productThumbnailUrl": "",
        "productDescription": "Grilled chicken wings served with chips & salad",
        "productPrice": 9.99,
        "menus": ["Main Menu", "Kids Menu"]
    },
    {
        "productName": "Mini Adana Kebab",
        "productId": "030",
        "category": "Kids",
        "productThumbnailUrl": "",
        "productDescription": "Mini Adana kebab served with chips & salad",
        "productPrice": 8.99,
        "menus": ["Main Menu", "Kids Menu"]
    },
    {
        "productName": "Falafel",
        "productId": "031",
        "category": "Kids",
        "productThumbnailUrl": "",
        "productDescription": "Falafel served with chips & salad",
        "productPrice": 7.99,
        "menus": ["Main Menu", "Kids Menu"]
    },
    {
        "productName": "Strawberry Cheesecake",
        "productId": "032",
        "category": "Desserts",
        "productThumbnailUrl": "",
        "productDescription": "Creamy cheesecake topped with fresh strawberries and a hint of vanilla, served on a graham cracker crust",
        "productPrice": 7.25,
        "menus": ["Main Menu", "Kids Menu"]
    },
    {
        "productName": "Honeycomb Chocolate Cheesecake",
        "productId": "033",
        "category": "Desserts",
        "productThumbnailUrl": "",
        "productDescription": "Rich chocolate cheesecake infused with honeycomb pieces, providing a delightful crunch in every bite",
        "productPrice": 7.25,
        "menus": ["Main Menu", "Kids Menu"]
    },
    {
        "productName": "Lotus Biscoff Cheesecake",
        "productId": "034",
        "category": "Desserts",
        "productThumbnailUrl": "",
        "productDescription": "Smooth cheesecake layered with Lotus Biscoff spread and topped with Biscoff cookie crumbles",
        "productPrice": 7.25,
        "menus": ["Main Menu", "Kids Menu"]
    },
    {
        "productName": "Ispanakli",
        "productId": "035",
        "category": "Gozleme",
        "productThumbnailUrl": "",
        "productDescription": "Traditional Turkish flat bread with spinach",
        "productPrice": 6.95,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Kasarli",
        "productId": "036",
        "category": "Gozleme",
        "productThumbnailUrl": "",
        "productDescription": "Traditional Turkish flat bread with cheddar cheese",
        "productPrice": 6.95,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Kiymali",
        "productId": "037",
        "category": "Gozleme",
        "productThumbnailUrl": "",
        "productDescription": "Traditional Turkish flat bread with minced meat",
        "productPrice": 7.95,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Karisik",
        "productId": "038",
        "category": "Gozleme",
        "productThumbnailUrl": "",
        "productDescription": "Traditional Turkish flat bread with spinach and cheese",
        "productPrice": 8.50,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Patatesli",
        "productId": "039",
        "category": "Gozleme",
        "productThumbnailUrl": "",
        "productDescription": "Traditional Turkish flat bread with potato",
        "productPrice": 7.95,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Peynirli",
        "productId": "040",
        "category": "Gozleme",
        "productThumbnailUrl": "",
        "productDescription": "Traditional Turkish flat bread with feta cheese",
        "productPrice": 6.95,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Make Your Own",
        "productId": "041",
        "category": "Gozleme",
        "productThumbnailUrl": "",
        "productDescription": "Traditional Turkish flat bread with three fillings. Choose from: Spinach, Minced Meat, Potato, Feta Cheese, Cheddar Cheese",
        "productPrice": 8.90,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Sultan’s Single Mediterranean Breakfast",
        "productId": "042",
        "category": "Specials",
        "productThumbnailUrl": "",
        "productDescription": "Egg, Turkish beef sausage, halloumi, sigara boregi, feta cheese, cottage cream, honey, jam, mushrooms, fresh yogurt, served with fresh salad and mixed olives",
        "productPrice": 16.50,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Sultan’s Sharing Breakfast For 2 Special",
        "productId": "043",
        "category": "Specials",
        "productThumbnailUrl": "",
        "productDescription": "Egg, Turkish beef sausage, potato wedges, halloumi, siagra boregi, honey, cottage cream, jam, tahini, grape molasses, butter, fresh homemade yogurt, cemen, cokelek, feta cheese, cheddar cheese, topil cheese, tel cheese, orgu cheese, grilled mushrooms, peppers, halva. Served with fresh salad",
        "productPrice": 38.99,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Shakshuka (V)",
        "productId": "044",
        "category": "Specials",
        "productThumbnailUrl": "",
        "productDescription": "Poached eggs in a spicy tomato sauce, seasoned with peppers, onion, garlic and various spices, served with sourdough bread",
        "productPrice": 12.49,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Mihlama (V)",
        "productId": "045",
        "category": "Specials",
        "productThumbnailUrl": "",
        "productDescription": "Traditional Turkish melted cheese dish made from different cheeses, butter, and corn flour",
        "productPrice": 13.99,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Vegan Breakfast",
        "productId": "046",
        "category": "Specials",
        "productThumbnailUrl": "",
        "productDescription": "Crushed avocado on vegan sourdough bread, mushrooms, hash brown, grilled tomato, beans, vegan sausage, and salad",
        "productPrice": 14.99,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Menemen",
        "productId": "047",
        "category": "Omelette",
        "productThumbnailUrl": "",
        "productDescription": "Omelet with tomato and peppers",
        "productPrice": 11.99,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Sucuklu",
        "productId": "048",
        "category": "Omelette",
        "productThumbnailUrl": "",
        "productDescription": "Omelet with Turkish beef sausage",
        "productPrice": 12.99,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Kasarli",
        "productId": "049",
        "category": "Omelette",
        "productThumbnailUrl": "",
        "productDescription": "Omelet with cheddar cheese",
        "productPrice": 11.99,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Peynirli",
        "productId": "050",
        "category": "Omelette",
        "productThumbnailUrl": "",
        "productDescription": "Omelet with feta cheese",
        "productPrice": 11.99,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Turkish Tea (S)",
        "productId": "051",
        "category": "Turkish Tea",
        "productThumbnailUrl": "",
        "productDescription": "Traditional Turkish tea served in a small glass",
        "productPrice": 2.95,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Turkish Tea (L)",
        "productId": "052",
        "category": "Turkish Tea",
        "productThumbnailUrl": "",
        "productDescription": "Traditional Turkish tea served in a large glass",
        "productPrice": 3.95,
        "menus": ["Breakfast Menu"]
    },
    {
        "productName": "Turkish Coffee",
        "productId": "053",
        "category": "Turkish Tea",
        "productThumbnailUrl": "",
        "productDescription": "Rich and aromatic Turkish coffee served in a traditional style",
        "productPrice": 3.25,
        "menus": ["Breakfast Menu"]
    }
  ]
}

const myData1 = {
  "additional_charges": [
    {
      "amount": 1.5,
      "operation": "%",
      "name": "Processing Fee",
      "platformCharge": true
    }
  ],
  menu: [
    {
       "productName":"Poppadoms",
       "productId":"001",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"A Selection of poppadoms accompanied by sultans signature chutneys",
       "productPrice":1.95
    },
    {
       "productName":"Hummus",
       "productId":"002",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"Blended chickpeas with tahini paste, fresh garlic, lemon and extra virgin olive oil",
       "productPrice":5.90
    },
    {
       "productName":"Cacik",
       "productId":"003",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"Cucumber dip with creamy yogurt",
       "productPrice":5.90
    },
    {
       "productName":"Patlıcan Soslu",
       "productId":"004",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"Aubergine, green and red pepper served in a special tomato sauce",
       "productPrice":6.90
    },
    {
       "productName":"Tabule",
       "productId":"005",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"A mixture of parsley, tomatoes, peppers, spring onion and olive oil",
       "productPrice":5.90
    },
    {
       "productName":"Grilled Halloumi",
       "productId":"006",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"Grilled halloumi (Goats cheese from Cyprus)",
       "productPrice":7.90
    },
    {
       "productName":"Sucuk Izgara",
       "productId":"007",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"Delicious garlic sausages, charcoal grilled",
       "productPrice":7.90
    },
    {
       "productName":"Falafel",
       "productId":"008",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"A blend chickpeas, broad beans, leeks, coriander, garlic and herbs",
       "productPrice":7.90
    },
    {
       "productName":"Muska Borek",
       "productId":"009",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"Deep fried filo pastry rolls stuffed with feta cheese, mint and herbs",
       "productPrice":7.90
    },
    {
       "productName":"Hummus Kavurma",
       "productId":"010",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"Traditional hummus topped with fine pan fried diced lamb",
       "productPrice":8.90
    },
    {
       "productName":"Kalamar",
       "productId":"011",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"Squid rings marinated & coated in light butter served with tartar sauce",
       "productPrice":8.90
    },
    {
       "productName":"Grilled Prawns",
       "productId":"012",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"6 Prawns, charcoal grilled served on a bed of mixed salad",
       "productPrice":11.90
    },
    {
       "productName":"Vegetable Samosa",
       "productId":"013",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"Deep fried filo pastry triangles stuffed with mixed vegetables",
       "productPrice":5.50
    },
    {
       "productName":"Mixed Mezze",
       "productId":"014",
       "category":"Starters",
       "productThumbnailUrl":"",
       "productDescription":"A Selection of 6 mezzes (Hummus Kavurma, Cacik, Halloumi, Tabule, Muska Borek and Patlican Soslu)",
       "productPrice":28.90
    },
    {
       "productName":"Chicken Shish Kebab",
       "productId":"015",
       "category":"Charcoal Grill",
       "productThumbnailUrl":"https://i.ibb.co/rfT0nNG/Chicken-Shish.jpg",
       "productDescription":"Marinated cubes of chicken breast, charcoal grilled, served with rice & mixed salad",
       "productPrice":17.90
    },
    {
       "productName":"Lamb Shish Kebab",
       "productId":"016",
       "category":"Charcoal Grill",
       "productThumbnailUrl":"",
       "productDescription":"Marinated cubes of lamb, charcoal grilled, served with rice & mixed salad",
       "productPrice":19.90
    },
    {
       "productName":"Iskender Kebab",
       "productId":"017",
       "category":"Charcoal Grill",
       "productThumbnailUrl":"",
       "productDescription":"Lamb shish, presented on a bed of crispy bread dressed in yogurt, Anatolian sauce & butter",
       "productPrice":22.90
    },
    {
       "productName":"Adana Kofte Kebab",
       "productId":"018",
       "category":"Charcoal Grill",
       "productThumbnailUrl":"https://i.ibb.co/VB834Fv/Adana-Kofte.jpg",
       "productDescription":"Minced lamb blended with spices & herbs charcoal grilled served with rice & mixed salad",
       "productPrice":18.90
    },
    {
       "productName":"Lamb Chops",
       "productId":"019",
       "category":"Charcoal Grill",
       "productThumbnailUrl":"https://i.ibb.co/F82wXy1/Lamb-Chops.jpg",
       "productDescription":"Best end-of-lamb cutlets with grilled tomatoes & rice",
       "productPrice":24.90
    },
    {
       "productName":"Lamb Ribs",
       "productId":"020",
       "category":"Charcoal Grill",
       "productThumbnailUrl":"https://i.ibb.co/HqtW98S/Lamb-Ribs.jpg",
       "productDescription":"Lamb spare ribs, charcoal grilled, served with rice & mixed salad",
       "productPrice":22.90
    },
    {
       "productName":"Chicken Wings",
       "productId":"021",
       "category":"Charcoal Grill",
       "productThumbnailUrl":"https://i.ibb.co/1sH2cG8/Chicken-Wings.jpg",
       "productDescription":"Marinated chicken wings, charcoal-grilled, served with rice & mixed salad",
       "productPrice":16.90
    },
    {
       "productName":"Mixed Shish",
       "productId":"022",
       "category":"Charcoal Grill",
       "productThumbnailUrl":"",
       "productDescription":"Marinated cubes of chicken & Lamb served with rice & mixed salad",
       "productPrice":18.90
    },
    {
       "productName":"Mixed Grill",
       "productId":"023",
       "category":"Charcoal Grill",
       "productThumbnailUrl":"",
       "productDescription":"Selection of BBQ dishes, chicken, lamb, kofte, wings served with rice and mixed salad",
       "productPrice":26.90
    },
    {
       "productName":"Sirloin Steak",
       "productId":"024",
       "category":"Charcoal Grill",
       "productThumbnailUrl":"",
       "productDescription":"Premium cut steak, charcoal-grilled, served with chips, salad and creamy mash potatoes",
       "productPrice":27.90
    },
    {
       "productName":"Sultans Grand Platter",
       "productId":"025",
       "category":"Charcoal Grill",
       "productThumbnailUrl":"https://i.ibb.co/PZ72V4S/Platter.jpg",
       "productDescription":"A Selection of Sultans grilled meats, Adana kofte-chicken wings-chicken shish-lamb shish lamb ribs served with rice & salad, suitable for 5-6 People",
       "productPrice":94.90
    },
    {
       "productName":"Grilled Sea Bass",
       "productId":"026",
       "category":"Seafood",
       "productThumbnailUrl":"https://i.ibb.co/37ThFx1/Seabass.jpg",
       "productDescription":"Fresh sea bass marinated & barbecued, served with chips & mixed salad",
       "productPrice":27.90
    },
    {
       "productName":"Salmon Sis",
       "productId":"027",
       "category":"Seafood",
       "productThumbnailUrl":"https://i.ibb.co/6FxZS2D/Salmon-Shish.jpg",
       "productDescription":"Fresh Salmon steaks marinated & barbecued,served with rice & mixed salad",
       "productPrice":24.90
    },
    {
       "productName":"Paneer Butter Masala",
       "productId":"028",
       "category":"Vegetarian Dishes",
       "productThumbnailUrl":"",
       "productDescription":"Rich and creamy curry mixed with paneer, onions, tomatoes, mixed blend spices and butter",
       "productPrice":15.99
    },
    {
       "productName":"Mixed Veg Curry",
       "productId":"029",
       "category":"Vegetarian Dishes",
       "productThumbnailUrl":"",
       "productDescription":"Aromatic Indian dish with fresh vegetables cooked to perfection",
       "productPrice":9.99
    },
    {
       "productName":"Aloo Saag",
       "productId":"030",
       "category":"Vegetarian Dishes",
       "productThumbnailUrl":"",
       "productDescription":"Potatoes fried in spices and spinach",
       "productPrice":11.49
    },
    {
       "productName":"Falafel",
       "productId":"031",
       "category":"Vegetarian Dishes",
       "productThumbnailUrl":"",
       "productDescription":"Deep fried chickpeas with herbs, crispy on the outside and tender on the inside served with hummus and a choice of mixed salad",
       "productPrice":14.90
    },
    {
       "productName":"Butter Chicken",
       "productId":"032",
       "category":"Curries and Stews",
       "productThumbnailUrl":"",
       "productDescription":"Our butter chicken, served in a unique and unforgettable way",
       "productPrice":17.90
    },
    {
       "productName":"Chicken Tikka Masala",
       "productId":"033",
       "category":"Curries and Stews",
       "productThumbnailUrl":"",
       "productDescription":"Charcoal grilled chicken tikka pieces, gently cooked in a brown onion, tomato and chili masala sauce. A dish of Royalty",
       "productPrice":17.90
    },
    {
       "productName":"Chicken Vindaloo",
       "productId":"034",
       "category":"Curries and Stews",
       "productThumbnailUrl":"https://i.ibb.co/4ZnPZK8/1.jpg",
       "productDescription":"Chicken simmered in zesty tangy curry sauce with rare spices",
       "productPrice":17.90
    },
    {
       "productName":"Lamb Karahi",
       "productId":"035",
       "category":"Curries and Stews",
       "productThumbnailUrl":"",
       "productDescription":"Tender lamb cooked with spices, chillies and ginger",
       "productPrice":18.90
    },
    {
       "productName":"Lamb Vindaloo",
       "productId":"036",
       "category":"Curries and Stews",
       "productThumbnailUrl":"",
       "productDescription":"Lamb simmered in zesty tangy curry sauce with rare spices",
       "productPrice":18.90
    },
    {
       "productName":"Saag Gosht",
       "productId":"037",
       "category":"Curries and Stews",
       "productThumbnailUrl":"",
       "productDescription":"Tender lamb cooked with fresh spinach & spices",
       "productPrice":18.90
    },
    {
       "productName":"Chicken Biryani",
       "productId":"038",
       "category":"Rice Dishes",
       "productThumbnailUrl":"",
       "productDescription":"Aromatic rice with chicken and spices",
       "productPrice":17.90
    },
    {
       "productName":"Lamb Biryani",
       "productId":"039",
       "category":"Rice Dishes",
       "productThumbnailUrl":"",
       "productDescription":"Aromatic rice with lamb and spices",
       "productPrice":18.90
    },
    {
       "productName":"Pilau Rice",
       "productId":"040",
       "category":"Rice Dishes",
       "productThumbnailUrl":"",
       "productDescription":"Flavored rice cooked to perfection",
       "productPrice":3.90
    },
    {
       "productName":"Plain Rice",
       "productId":"041",
       "category":"Rice Dishes",
       "productThumbnailUrl":"",
       "productDescription":"Simple steamed rice",
       "productPrice":2.95
    },
    {
       "productName":"Plain Naan",
       "productId":"042",
       "category":"Naan",
       "productThumbnailUrl":"",
       "productDescription":"Traditional plain naan bread",
       "productPrice":2.50
    },
    {
       "productName":"Butter Naan",
       "productId":"043",
       "category":"Naan",
       "productThumbnailUrl":"",
       "productDescription":"Naan bread brushed with butter",
       "productPrice":3.00
    },
    {
       "productName":"Tandoori Roti",
       "productId":"044",
       "category":"Naan",
       "productThumbnailUrl":"",
       "productDescription":"Whole wheat roti cooked in a tandoor",
       "productPrice":2.00
    },
    {
       "productName":"Garlic Naan",
       "productId":"045",
       "category":"Naan",
       "productThumbnailUrl":"",
       "productDescription":"Naan bread with a garlic topping",
       "productPrice":3.90
    },
    {
       "productName":"Peshwari Naan",
       "productId":"046",
       "category":"Naan",
       "productThumbnailUrl":"",
       "productDescription":"Sweet naan bread stuffed with coconut and raisins",
       "productPrice":4.50
    },
    {
       "productName":"Cheese Naan",
       "productId":"047",
       "category":"Naan",
       "productThumbnailUrl":"",
       "productDescription":"Naan bread stuffed with cheese",
       "productPrice":3.50
    },
    {
       "productName":"Baklava",
       "productId":"048",
       "category":"Desserts",
       "productThumbnailUrl":"",
       "productDescription":"Sweet layered pastry dessert with nuts and honey",
       "productPrice":6.90
    },
    {
       "productName":"Matka Kulfi",
       "productId":"049",
       "category":"Desserts",
       "productThumbnailUrl":"",
       "productDescription":"Traditional Indian ice cream served in a clay pot",
       "productPrice":5.50
    },
    {
       "productName":"Rasmalai",
       "productId":"050",
       "category":"Desserts",
       "productThumbnailUrl":"",
       "productDescription":"Indian dessert made from paneer soaked in sweetened milk",
       "productPrice":6.50
    },

    {
      "category": "Desserts",
      "productName": "Chocolate Cheesecake",
      "productPrice": 7.25,
      "productId": "051",
      "productDescription": "Creamy cheesecake with rich chocolate and a graham cracker crust. Decadent and irresistible.",
      "productThumbnailUrl": ""
    },
    {
      "productPrice": 7.25,
      "productDescription": "Creamy cheesecake with fresh strawberry compote on a buttery graham cracker crust. Fruity and rich.",
      "productId": "052",
      "productName": "Strawberry Cheesecake",
      "productThumbnailUrl": "",
      "category": "Desserts"
    },
    {
      "productName": "Lotus Biscoff Cheesecake",
      "productThumbnailUrl": "",
      "productId": "053",
      "productPrice": 7.25,
      "productDescription": "Creamy cheesecake with spiced Biscoff spread and a crunchy Biscoff crust. Rich and indulgent.",
      "category": "Desserts"
    }
 ]
  
  }



const RoutePageRoutes = (props: Props) => {

    const [productData, setProductData] = React.useState([]);
    const [menuSettings, setMenuSettings] = React.useState([]);
    const [openingHours, setOpeningHours] = React.useState([]);
    const [additionalCharges, setAdditionalCharges] = React.useState([]);
    const [activeUserId, setActiveUserId] = React.useState("");
    const [cart, setCart] = React.useState<any[]>([]);

    const [showTopbar, setShowTopbar] = React.useState(false);
    const [showSidebar, setShowSidebar] = React.useState(false);
    const [homePageMode, setHomePageMode] = React.useState(false);

    // const [backgroundColor, setBackgroundColor] = React.useState("black");
    // const []

  // React.useEffect(() => {
  //   console.log("HOME PAGE MODE UPDATED: ", homePageMode)
  // }, [homePageMode])

  const setMenu = (e: any) => {
    setProductData(e.data.menu);
    setMenuSettings(e.data.menuSettings);
    setOpeningHours(e.data.openingHours);
    setAdditionalCharges(e.data.additionalCharges)
    let localData = localStorage.getItem("dp-appId-cart") || "";
    if (localData !== "") {
      setCart(JSON.parse(localData));
    }
  }

  React.useEffect(() => {
    // DataService.addData("restaurants", myData, undefined, "sultans-lounge")
    DataService.GetData("restaurants", "sultans-lounge", undefined, setMenu).then((result) => {
        setProductData(result.menu);
        setMenuSettings(result.menuSettings);
        setOpeningHours(result.openingHours);
        setAdditionalCharges(result.additionalCharges);
    });
    let localData = localStorage.getItem("dp-appId-cart") || "";
    if (localData !== "") {
      setCart(JSON.parse(localData));
    }
  }, [])

  React.useEffect(() => {
    localStorage.setItem("dp-appId-cart", JSON.stringify(cart));
  }, [cart])

  const [sidebarPages, setSidebarPages] = React.useState<any[]>([])

  React.useEffect(() => {

    let cartText = "Cart";
    if (cart.length > 0) {
      cartText = `Cart (${cart.length})`;
    }

    if (props.testMode) {
      setSidebarPages([
        {buttonText: "Home", url: process.env.REACT_APP_HOME_STORE_URL, iconName: "HiHome"},
        {buttonText: "Order", url: "/test/order", iconName: "HiPencilAlt"},
        {buttonText: "Find Us", url: "/test/location", iconName: "HiLocationMarker"},
        {buttonText: "Contact", url: "/test/contact", iconName: "HiPhone"},
        {buttonText: cartText, url: "/test/cart", iconName: "HiShoppingCart", hideInDesktopView: true}
      ])
    }
    else {
      setSidebarPages([
        {buttonText: "Home", url: process.env.REACT_APP_HOME_STORE_URL, iconName: "HiHome"},
        {buttonText: "Order", url: ["/", "/pickup", "/dinein"], iconName: "HiPencilAlt"},
        {buttonText: "Find Us", url: "/location", iconName: "HiLocationMarker"},
        {buttonText: "Contact", url: "/contact", iconName: "HiPhone"},
        {buttonText: cartText, url: "/cart", iconName: "HiShoppingCart", hideInDesktopView: true}
      ])
    }
  }, [cart])



    return (
      <ThemeProvider>
        <RootPage
            sidebarPages={sidebarPages}
        >


          <Routes>
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            {/* <Route path="/" element={<SitesPage activeUserId={activeUserId} />} /> */}
            {/* <Route path="/order" element={<OrderPage cart={cart} setCart={setCart} menuSettings={menuSettings} openingHours={openingHours} productData={productData || []} additionalCharges={additionalCharges} testMode={props.testMode || false}/>} /> */}
            <Route path="/" element={<OrderPage cart={cart} setCart={setCart} menuSettings={menuSettings} openingHours={openingHours} productData={productData || []} additionalCharges={additionalCharges} testMode={props.testMode || false}/>} />
            <Route path="/pickup" element={<OrderPage cart={cart} setCart={setCart} menuSettings={menuSettings} openingHours={openingHours} productData={productData || []} additionalCharges={additionalCharges} testMode={props.testMode || false}/>} />
            <Route path="/dinein" element={<OrderPage cart={cart} setCart={setCart} menuSettings={menuSettings} openingHours={openingHours} productData={productData || []} additionalCharges={additionalCharges} testMode={props.testMode || false}/>} />
            <Route path="/orderConfirmation" element={<OrderConfirmationPage />} />
            <Route path="/cart" element={<CartPage cart={cart} setCart={setCart} menuSettings={menuSettings} openingHours={openingHours} productData={productData || []} additionalCharges={additionalCharges} testMode={props.testMode || false}/>} />
            <Route path="/location" element={
              <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d621.1618033371224!2d-0.3107067181138064!3d51.482989594751636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760db75834c5bd%3A0x300b26c8c6d11e30!2sUnit-1%2C%20Brentford%20Lock%2C%20High%20St%2C%20Brentford%20TW8%208AQ%2C%20UK!5e0!3m2!1sen!2sin!4v1718359389686!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map"
            ></iframe>
            } />
            <Route path="/contact" element={
              <div style={{display: "flex", flexDirection: "column", textAlign: "left", padding: "5%"}}>

                <h2 style={{fontWeight: "300"}}>CONTACT US</h2>
                <div style={{display: "flex"}}>
                  <span style={{fontWeight: "bold"}}>Address: </span><span style={{marginLeft: "10px"}}>High St Brentford Lock, Brentford, TW8 8LF</span>
                </div>
                <div style={{display: "flex", marginTop: "10px"}}>
                  <span style={{fontWeight: "bold"}}>Email: </span><span style={{marginLeft: "10px"}}>info@sultanslounge.co.uk</span>
                </div>

                <h2 style={{fontWeight: "300", marginTop: "40px"}}>OPENING HOURS</h2>
                <span style={{fontWeight: "bold"}}>Monday to Saturday</span>
                <span style={{marginTop: "10px"}}>11:00 - 14:30</span>
                <span style={{marginTop: "10px"}}>17:30 - 22:30</span>

                <span style={{marginTop: "10px", fontWeight: "bold"}}>Sunday</span>
                <span style={{marginTop: "10px"}}>18:00 - 23:30</span>
              </div>
            } />


          </Routes>

        </RootPage>
      </ThemeProvider>
    )
}

export default RoutePageRoutes;